// Dependencies
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
// Core & Lab
import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
// Types
import { AppState } from '../../../../Reducers/Reducers';

const PictureProgress: FC = (): ReactElement => {
    const { t } = useTranslation();
    const progress = useSelector((state: AppState) => state.picture.progress);
    return (
        <Dialog PaperProps={{className: 'ft-pictureProgress'}} fullWidth maxWidth={ 'sm' } open={ progress > 0 }>
            <LinearProgress variant={ 'determinate' } value={ progress }/>
            <Typography className={ 'ft-pictureProgressHelper' }>{ t('shared.upload-picture(s)-progress') }</Typography>
        </Dialog>
    );
};

export default PictureProgress;