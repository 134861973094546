// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { Config } from '../objects/config';
import { HeaderState } from '../objects/headerState';

const initialState: HeaderState = {
    client_id: null,
    favicon: null,
    google: null,
    keys: [],
    logo: null,
    quotation_code: null,
    dataAllFetchedFor: {}
};

export const headerSlice = createSlice({
    name: 'header',
    initialState,
    reducers: {
        setConfig: (state, action: PayloadAction<Config>) => {
            const { client_id, logo, keys, favicon, quotation_code } = action.payload;
            state.client_id = client_id;
            state.logo = logo;
            state.keys = keys;
            state.favicon = favicon;
            state.quotation_code = quotation_code;
        },
        setDataAllFetchedFor: (
            state,
            action: PayloadAction<{id: number}>
        ): void => {
            state.dataAllFetchedFor[action.payload.id] = true;
        },
        setGoogle: (state, action: PayloadAction<any>) => {
            state.google = action.payload;
        },
    }
});


export const {
    setConfig,
    setDataAllFetchedFor,
    setGoogle
} = headerSlice.actions;

export default headerSlice.reducer;