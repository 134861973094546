import { Block } from "../objects/block";

export function insertBlockAtIndex(options: {
    blocks: Block<any>[],
    block: Block<any>,
    index: number
}): Block<any>[] {
    return [
        ...options.blocks.slice(0, options.index),
        options.block,
        ...options.blocks.slice(options.index)
    ];
}