import React from "react";
import { Grid, InputAdornment, Slider, TextField } from "@mui/material";
import {isArray} from "lodash";

type Props = {
    value: number,
    onChange: (value: number) => void,
    measureUnit?: string,
    min?: number,
    max?: number
}

export function MailTemplateVisualEditorNumberPicker(props: Props): JSX.Element {
    return (
        <Grid container spacing={2}>
            <Grid item xs={9}>
                <Slider
                    value={props.value}
                    onChange={(event, value) => props.onChange(isArray(value) ? (value?.[0] ?? 0) : value)}
                    min={props.min}
                    max={props.max}
                />
            </Grid>
            <Grid item xs={3}>
                <TextField
                    type="number"
                    size="small"
                    value={props.value}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">{props.measureUnit}</InputAdornment>,
                    }}
                    onChange={(event) => {
                        props.onChange(parseInt(event.target.value));
                    }}
                />
            </Grid>
        </Grid>
    );
}