// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { Currency } from '../objects/currency';
import { CurrencyState } from '../objects/currencyState';

const initialState: CurrencyState = {
    currencies: null
};

export const currencySlice = createSlice({
    name: 'currency',
    initialState,
    reducers: {
        setCurrencies: (state, action: PayloadAction<Currency[]>) => {
            state.currencies = action.payload;
        }
    }
});


export const {
    setCurrencies
} = currencySlice.actions;

export default currencySlice.reducer;