//Dependencies
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//Core & Lab
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Accordion from '@mui/material/Accordion';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
//Icons
import Close from '@mui/icons-material/Close';
//Components
import PictureProgress from './pictureProgress';
import PictureModalCreate from './pictureModalCreate';
import PictureModalCreateTabs from './pictureModalCreateTabs';
//Actions
import { resetCreateForm, toggleCreate } from './redux/actions';
//Utils
import { PictureContext, PictureContextValue } from './utils/pictureContext';
//Types
import { AppState } from '../../../../Reducers/Reducers';

type Props = PictureContextValue & {
    extraContent?: JSX.Element,
    hidePictureHelper?: boolean,
}

const PictureModalCreateForm: FC<Props> = (props): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const create = useSelector((state: AppState) => state.picture.create);
    const onToggleCreate = () => {
        dispatch(toggleCreate());
    };

    useEffect(() => {
        return () => {
            dispatch(resetCreateForm());
        };
    }, []);

    return (
        <PictureContext.Provider value={props}>
            <Dialog fullScreen open={ create } onClose={ onToggleCreate }>
                <AppBar className={ 'ft-user-appBar' }>
                    <Toolbar>
                        <IconButton edge={ 'start' } color={ 'inherit' } onClick={ onToggleCreate }><Close/></IconButton>
                        <Typography variant={ 'h6' }>
                            {
                                t(
                                    props.singleFile ? 'shared.add-picture' : 'shared.add-picture(s)'
                                )
                            }
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Container className={ 'ft-container' } maxWidth={ 'md' }>
                    {props.extraContent}
                    <Accordion square expanded>
                        <AccordionSummary className={ 'ft-user-title' }>
                            <Typography>{ t('shared.picture-selection') }</Typography>
                        </AccordionSummary>
                        <AccordionDetails className={ 'ft-user-subContainer' }>
                            <Grid container spacing={ 2 }>
                                {
                                    !props.hidePictureHelper &&
                                    <Grid item xs={ 12 }>
                                        <Alert className={ 'ft-justify' } severity={ 'info' }>
                                            <AlertTitle>{ t('shared.info') } :</AlertTitle>
                                            { t('shared.add-picture-helper') }
                                        </Alert>
                                    </Grid>
                                }
                                <Grid item xs={ 12 }>
                                    <PictureModalCreateTabs/>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                    <Grid container spacing={ 2 } justifyContent={ 'flex-end' }>
                        <Grid item>
                            <Button onClick={ onToggleCreate }>{ t('cancel') }</Button>
                        </Grid>
                        <Grid item>
                            <PictureModalCreate/>
                        </Grid>
                    </Grid>
                </Container>
            </Dialog>
            <PictureProgress/>
        </PictureContext.Provider>
    );
};

export default PictureModalCreateForm;
