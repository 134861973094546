import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { setLogin } from "../component/profile/redux/reducer";
import { User } from "../component/user/objects/user";
import { AppState } from "../reducers";

export function useAutoLogin(): {loggingIn: boolean} {
    const dispatch = useDispatch();
    const clientId = useSelector((state: AppState) => state.header.client_id);
    const userId = useSelector((state: AppState) => state.profile.id);
    const profile = useSelector((state: AppState) => state.profile.profile);
    const token = useSelector((state: AppState) => state.profile.token);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const storedId = localStorage.getItem('user_id');
        const storedToken = localStorage.getItem('token');
        if (storedId && storedToken && clientId && !userId && !profile && !token) {
            (async () => {
                try {
                    setLoading(true);
                    const response = await axios.get<User>(
                        `${API_HREF}client/${clientId}/user/${storedId}/`,
                        {
                            headers: {'Authorization': `Token ${storedToken}`}
                        }
                    );
                    dispatch(setLogin({token: storedToken, user: response.data}));
                } catch (error: any) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            })();
        }
    }, [clientId, userId, profile, token]);

    return {loggingIn: loading};
}