import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit/dist/createAction";
// Types
import { Steps } from "../objects/steps";
import { CartState } from "../objects/cartState";
import { Currency } from "../../currency/objects/currency";
import { ManualProduct } from "../../cart/objects/manualProduct";
import { AccommodationProductRoom } from "../../accommodation/objects/accommodationProductRoom";

const initialState: CartState = {
  automatic_products_open: false,
  blocs: [{accommodation: null, contract: null, end_date: null, room: null, room_nb: 1, start_date: null}],
  blocs_create: false,
  on_request_product: null,
  manual_products: [],
  manual_product_open: false,
  manual_product_id: null,
  manual_product_name: '',
  manual_product_type: 16,
  manual_product_start_date: null,
  manual_product_end_date: null,
  manual_product_provider: null,
  manual_product_purchase_price: '',
  manual_product_purchase_currency: null,
  manual_product_margin_type: 'PER',
  manual_product_margin: '',
  manual_product_tva: '',
  manual_product_tva_is_margin_tva: false,
  manual_product_description: '',
  manual_product_note: '',
  roomChangePrices: {},
  step: Steps.CART,
  selected_room_modification_fees: null,
  linked_product_id: null,
  comment_dialog: null,
  companion_dialog: null
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setRoomChangeVariants: (state, action: PayloadAction<{roomId: number, data: CartState['roomChangePrices'][number]}>): void => {
      state.roomChangePrices[action.payload.roomId] = action.payload.data;
    },
    setStep: (state, action: PayloadAction<Steps>) => {
      state.step = action.payload;
    },
    setOnRequestProducts: (state, action) => {
      state.on_request_product = action.payload;
    },
    setManualProducts: (state, action: PayloadAction<ManualProduct[]>) => {
      state.manual_products = action.payload;
    },
    updateManualProductsAfterBooking: (state, action: PayloadAction<ManualProduct[]>) => {
      state.manual_products = state.manual_products.map((item) => {
        const replacement = action.payload.find((product) => product.id === item.id);
        return replacement ?? item;
      });
    },
    addManualProduct: (state, action: PayloadAction<ManualProduct>) => {
      state.manual_products = [...state.manual_products, action.payload];
      state.automatic_products_open = false;
      state.manual_product_open = false;
      state.manual_product_id = null;
      state.manual_product_name = '';
      state.manual_product_type = 16;
      state.manual_product_start_date = null;
      state.manual_product_end_date = null;
      state.manual_product_provider = null;
      state.manual_product_purchase_price = '';
      state.manual_product_purchase_currency = null;
      state.manual_product_margin_type = 'PER';
      state.manual_product_margin = '';
      state.manual_product_tva = '';
      state.manual_product_tva_is_margin_tva = false;
      state.manual_product_description = '';
      state.manual_product_note = '';
    },
    deleteManualProduct: (state, action: PayloadAction<number>) => {
      state.manual_products = [...state.manual_products].filter(product => product.id !== action.payload);
    },
    openManualProduct: (state) => {
      state.manual_product_open = true;
    },
    resetManualProduct: (state) => {
      state.manual_product_open = false;
      state.manual_product_id = null;
      state.manual_product_name = '';
      state.manual_product_type = 16;
      state.manual_product_start_date = null;
      state.manual_product_end_date = null;
      state.manual_product_provider = null;
      state.manual_product_purchase_price = '';
      state.manual_product_purchase_currency = null;
      state.manual_product_margin_type = 'PER';
      state.manual_product_margin = '';
      state.manual_product_tva = '';
      state.manual_product_tva_is_margin_tva = false;
      state.manual_product_description = '';
      state.manual_product_note = '';
      state.linked_product_id = null;
    },
    updateManualProductCart: (state, action: PayloadAction<ManualProduct>) => {
      state.manual_products = [...state.manual_products, action.payload];
    },
    updateManualProductType: (state, action: PayloadAction<11 | 16>) => {
      state.manual_product_type = action.payload;
    },
    updateManualProductName: (state, action: PayloadAction<string>) => {
      state.manual_product_name = action.payload;
    },
    updateManualProductStartDate: (state, action: PayloadAction<Date>) => {
      state.manual_product_start_date = action.payload;
    },
    updateManualProductEndDate: (state, action: PayloadAction<Date>) => {
      state.manual_product_end_date = action.payload;
    },
    updateManualProductPurchasePrice: (state, action: PayloadAction<string>) => {
      state.manual_product_purchase_price = action.payload;
    },
    updateManualProductPurchaseCurrency: (state, action: PayloadAction<Currency>) => {
      state.manual_product_purchase_currency = action.payload;
    },
    updateManualProductMarginType: (state, action: PayloadAction<"FIX" | "PER">) => {
      state.manual_product_margin_type = action.payload;
    },
    updateManualProductMargin: (state, action: PayloadAction<string>) => {
      state.manual_product_margin = action.payload;
    },
    updateManualProductDescription: (state, action: PayloadAction<string>) => {
      state.manual_product_description = action.payload;
    },
    updateManualProductNote: (state, action: PayloadAction<string>) => {
      state.manual_product_note = action.payload;
    },
    toggleBlocAccommodationForm: (state): void => {
      state.blocs_create = !state.blocs_create;
    },
    addBloc: (state) => {
      state.blocs = [...state.blocs, {accommodation: null, contract: null, end_date: state.end_date, room: null, room_nb: 1, start_date: state.start_date}];
    },
    deleteBloc: (state, action: PayloadAction<number>) => {
      const blocs = [...state.blocs];
      blocs.splice(action.payload, 1);
      state.blocs = blocs;
    },
    updateBlocAccommodation: (state, action: PayloadAction<{bloc_index: number, accommodation: null | ManualAccommodation}>) => {
      const { bloc_index, accommodation } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.accommodation = accommodation;
      bloc.contract = null;
      bloc.room = null;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocContract: (state, action: PayloadAction<{bloc_index: number, contract: null | Contract}>) => {
      const { bloc_index, contract } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.contract = contract;
      bloc.room = null;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocEndDate: (state, action: PayloadAction<{bloc_index: number, date: null | Date}>) => {
      const { bloc_index, date } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.end_date = date;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocRoom: (state, action: PayloadAction<{bloc_index: number, room: null | Room}>) => {
      const { bloc_index, room } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.room = room;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocRoomNb: (state, action: PayloadAction<{bloc_index: number, room_nb: string | number}>) => {
      const { bloc_index, room_nb } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.room_nb = room_nb;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    updateBlocStartDate: (state, action: PayloadAction<{bloc_index: number, date: null | Date}>) => {
      const { bloc_index, date } = action.payload;
      const blocs = [...state.blocs];
      const bloc = {...blocs[bloc_index]};
      bloc.start_date = date;
      blocs[bloc_index] = bloc;
      state.blocs = blocs;
    },
    toggleAncillaryProductsOpen: (state) => {
      state.automatic_products_open = !state.automatic_products_open;
    },
    setSelectedRoomModificationFees: (state, action: PayloadAction<null | AccommodationProductRoom>) => {
      state.selected_room_modification_fees = action.payload;
    },
    setLinkedProductId: (state, action: PayloadAction<null | number>) => {
      state.linked_product_id = action.payload;
      state.manual_product_open = true;
    },
    setOpenCommentDialog: (state, action: PayloadAction<null | number>) => {
      state.comment_dialog = action.payload;
    },
    setOpenCompanionDialog: (state, action: PayloadAction<null | number>) => {
      state.companion_dialog = action.payload;
    }
  }
});

export const {
  setRoomChangeVariants,
  setStep,
  setOnRequestProducts,
  setManualProducts,
  addManualProduct,
  deleteManualProduct,
  openManualProduct,
  resetManualProduct,
  updateManualProductCart,
  updateManualProductType,
  updateManualProductName,
  updateManualProductStartDate,
  updateManualProductEndDate,
  updateManualProductPurchasePrice,
  updateManualProductPurchaseCurrency,
  updateManualProductMarginType,
  updateManualProductMargin,
  updateManualProductDescription,
  updateManualProductNote,
  toggleBlocAccommodationForm,
  addBloc,
  deleteBloc,
  updateBlocAccommodation,
  updateBlocContract,
  updateBlocEndDate,
  updateBlocRoom,
  updateBlocRoomNb,
  updateBlocStartDate,
  toggleAncillaryProductsOpen,
  setSelectedRoomModificationFees,
  setLinkedProductId,
  setOpenCommentDialog,
  setOpenCompanionDialog
} = cartSlice.actions;

export default cartSlice;