//Dependencies
import clsx from 'clsx';
import { Map } from 'immutable';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { convertToHTML, convertFromHTML, Tag } from 'draft-convert';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import {
    AtomicBlockUtils,
    Editor,
    EditorState,
    RichUtils,
    DefaultDraftBlockRenderMap,
    convertToRaw,
    Modifier,
    ContentState,
    DraftHandleValue
} from 'draft-js';
//Core & Lab
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import ClickAwayListener from '@mui/material/ClickAwayListener';
//Icons
import AddLink from '@mui/icons-material/AddLink';
import Palette from '@mui/icons-material/Palette';
import FormatBold from '@mui/icons-material/FormatBold';
import PhotoLibrary from '@mui/icons-material/PhotoLibrary';
import FormatItalic from '@mui/icons-material/FormatItalic';
import StrikethroughS from '@mui/icons-material/StrikethroughS';
import FormatAlignLeft from '@mui/icons-material/FormatAlignLeft';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import FormatAlignRight from '@mui/icons-material/FormatAlignRight';
import FormatUnderlined from '@mui/icons-material/FormatUnderlined';
import FormatAlignCenter from '@mui/icons-material/FormatAlignCenter';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import FormatListBulleted from '@mui/icons-material/FormatListBulleted';
import FormatListNumbered from '@mui/icons-material/FormatListNumbered';
import FormatAlignJustify from '@mui/icons-material/FormatAlignJustify';
import FormatIndentIncrease from '@mui/icons-material/FormatIndentIncrease';
import FormatIndentDecrease from '@mui/icons-material/FormatIndentDecrease';
import UndoIcon from '@mui/icons-material/Undo';
import RedoIcon from '@mui/icons-material/Redo';
//Components
import decorator from './decorator';
import { customColors } from './customColors';
//Actions
import { toggleCreate } from '../../picture/redux/actions';
//Types
import { Picture } from '../../picture/objects/picture';
import { AppState } from '../../../../../Reducers/Reducers';
import { RichEditorFontSizeControl, RichEditorFontSizeStylesMap } from './richEditorFontSizeControl';
import { Tooltip } from '@mui/material';

export const RichEditorStyleMap: {[index: string]: React.CSSProperties} = {
    STRIKETHROUGH: {
        textDecoration: 'line-through'
    },
    COLOR_BLACK: {
        color: '#000000'
    },
    COLOR_DARK_GRAY_4: {
        color: '#434343'
    },
    COLOR_DARK_GRAY_3: {
        color: '#666666'
    },
    COLOR_DARK_GRAY_2: {
        color: '#999999'
    },
    COLOR_DARK_GRAY_1: {
        color: '#B7B7B7'
    },
    COLOR_GRAY: {
        color: '#CCCCCC'
    },
    COLOR_LIGHT_GRAY_1: {
        color: '#D9D9D9'
    },
    COLOR_LIGHT_GRAY_2: {
        color: '#EFEFEF'
    },
    COLOR_LIGHT_GRAY_3: {
        color: '#F3F3F3'
    },
    COLOR_WHITE: {
        color: '#FFFFFF'
    },
    COLOR_RED_BERRY: {
        color: '#980000'
    },
    COLOR_RED: {
        color: '#FF0000'
    },
    COLOR_ORANGE: {
        color: '#FF9900'
    },
    COLOR_YELLOW: {
        color: '#FFFF00'
    },
    COLOR_GREEN: {
        color: '#00FF00'
    },
    COLOR_CYAN: {
        color: '#00FFFF'
    },
    COLOR_CORNFLOWER_BLUE: {
        color: '#4A86E8'
    },
    COLOR_BLUE: {
        color: '#0000FF'
    },
    COLOR_PURPLE: {
        color: '#9900FF'
    },
    COLOR_MAGENTA: {
        color: '#FF00FF'
    },
    COLOR_LIGHT_RED_BERRY_3: {
        color: '#E6B8AF'
    },
    COLOR_LIGHT_RED_3: {
        color: '#F4CCCC'
    },
    COLOR_LIGHT_ORANGE_3: {
        color: '#FCE5CD'
    },
    COLOR_LIGHT_YELLOW_3: {
        color: '#FFF2CC'
    },
    COLOR_LIGHT_GREEN_3: {
        color: '#D9EAD3'
    },
    COLOR_LIGHT_CYAN_3: {
        color: '#D0E0E3'
    },
    COLOR_LIGHT_CORNFLOWER_BLUE_3: {
        color: '#C9DAF8'
    },
    COLOR_LIGHT_BLUE_3: {
        color: '#CFE2F3'
    },
    COLOR_LIGHT_PURPLE_3: {
        color: '#D9D2E9'
    },
    COLOR_LIGHT_MAGENTA_3: {
        color: '#EAD1DC'
    },
    COLOR_LIGHT_RED_BERRY_2: {
        color: '#DD7E6B'
    },
    COLOR_LIGHT_RED_2: {
        color: '#EA9999'
    },
    COLOR_LIGHT_ORANGE_2: {
        color: '#F9CB9C'
    },
    COLOR_LIGHT_YELLOW_2: {
        color: '#FFE599'
    },
    COLOR_LIGHT_GREEN_2: {
        color: '#B6D7A8'
    },
    COLOR_LIGHT_CYAN_2: {
        color: '#A2C4C9'
    },
    COLOR_LIGHT_CORNFLOWER_BLUE_2: {
        color: '#A4C2F4'
    },
    COLOR_LIGHT_BLUE_2: {
        color: '#9FC5E8'
    },
    COLOR_LIGHT_PURPLE_2: {
        color: '#B4A7D6'
    },
    COLOR_LIGHT_MAGENTA_2: {
        color: '#D5A6BD'
    },
    COLOR_LIGHT_RED_BERRY_1: {
        color: '#CC4125'
    },
    COLOR_LIGHT_RED_1: {
        color: '#E06666'
    },
    COLOR_LIGHT_ORANGE_1: {
        color: '#F6B26B'
    },
    COLOR_LIGHT_YELLOW_1: {
        color: '#FFD966'
    },
    COLOR_LIGHT_GREEN_1: {
        color: '#93C47D'
    },
    COLOR_LIGHT_CYAN_1: {
        color: '#76A5AF'
    },
    COLOR_LIGHT_CORNFLOWER_BLUE_1: {
        color: '#6D9EEB'
    },
    COLOR_LIGHT_BLUE_1: {
        color: '#6FA8DC'
    },
    COLOR_LIGHT_PURPLE_1: {
        color: '#8E7CC3'
    },
    COLOR_LIGHT_MAGENTA_1: {
        color: '#C27BA0'
    },
    COLOR_DARK_RED_BERRY_1: {
        color: '#A61C00'
    },
    COLOR_DARK_RED_1: {
        color: '#CC0000'
    },
    COLOR_DARK_ORANGE_1: {
        color: '#E69138'
    },
    COLOR_DARK_YELLOW_1: {
        color: '#F1C232'
    },
    COLOR_DARK_GREEN_1: {
        color: '#6AA84F'
    },
    COLOR_DARK_CYAN_1: {
        color: '#45818E'
    },
    COLOR_DARK_CORNFLOWER_BLUE_1: {
        color: '#3C78D8'
    },
    COLOR_DARK_BLUE_1: {
        color: '#3D85C6'
    },
    COLOR_DARK_PURPLE_1: {
        color: '#674EA7'
    },
    COLOR_DARK_MAGENTA_1: {
        color: '#A64D79'
    },
    COLOR_DARK_RED_BERRY_2: {
        color: '#85200C'
    },
    COLOR_DARK_RED_2: {
        color: '#990000'
    },
    COLOR_DARK_ORANGE_2: {
        color: '#B45F06'
    },
    COLOR_DARK_YELLOW_2: {
        color: '#BF9000'
    },
    COLOR_DARK_GREEN_2: {
        color: '#38761D'
    },
    COLOR_DARK_CYAN_2: {
        color: '#134F5C'
    },
    COLOR_DARK_CORNFLOWER_BLUE_2: {
        color: '#1155CC'
    },
    COLOR_DARK_BLUE_2: {
        color: '#0B5394'
    },
    COLOR_DARK_PURPLE_2: {
        color: '#351C75'
    },
    COLOR_DARK_MAGENTA_2: {
        color: '#741B47'
    },
    COLOR_DARK_RED_BERRY_3: {
        color: '#5B0F00'
    },
    COLOR_DARK_RED_3: {
        color: '#660000'
    },
    COLOR_DARK_ORANGE_3: {
        color: '#783F04'
    },
    COLOR_DARK_YELLOW_3: {
        color: '#7F6000'
    },
    COLOR_DARK_GREEN_3: {
        color: '#274E13'
    },
    COLOR_DARK_CYAN_3: {
        color: '#0C343D'
    },
    COLOR_DARK_CORNFLOWER_BLUE_3: {
        color: '#1C4587'
    },
    COLOR_DARK_BLUE_3: {
        color: '#073763'
    },
    COLOR_DARK_PURPLE_3: {
        color: '#20124D'
    },
    COLOR_DARK_MAGENTA_3: {
        color: '#4C1130'
    }
};

const blockRenderMap = Map({
    'align-left': {
        element: 'div',
        wrapper: <div style={{ textAlign: 'left' }}/>
    },
    'align-center': {
        element: 'div',
        wrapper: <div style={{ textAlign: 'center' }}/>
    },
    'align-right': {
        element: 'div',
        wrapper: <div style={{ textAlign: 'right' }}/>
    },
    'align-justify': {
        element: 'div',
        wrapper: <div style={{ textAlign: 'justify' }}/>
    }
});

const extendedBlockRenderMap = DefaultDraftBlockRenderMap.merge(blockRenderMap);

type Toolbar =
    'text-type' |
    'font-size' |
    'alignment' |
    'indent' |
    'color' |
    'link' |
    'format' |
    'image' |
    'lists' |
    'undo'

export const EditorDefaultActivatedToolbarControls: Toolbar[] = [
    'text-type',
    'alignment',
    'indent',
    'color',
    'link',
    'format',
    'image',
    'lists',
    'undo'
];

type RichEditorProps = {
    id: string,
    pictures: null | Picture[],
    editorState: EditorState,
    setEditorState: any,
    action?: (locale: number, html: string) => {type: string} & unknown,
    values: Record<string, unknown> & {[index: string]: string},
    contentContainerStyles?: React.CSSProperties,
    hideToolbar?: boolean,
    noDefault?: boolean,
    controlledState?: boolean,
    children?: (editor: JSX.Element) => JSX.Element,
    toolbars?: Toolbar[],
    centerToolbars?: boolean
}

const RichEditor = React.forwardRef<Editor, RichEditorProps>(({
    id,
    pictures,
    editorState,
    setEditorState,
    action,
    values,
    contentContainerStyles,
    hideToolbar,
    noDefault,
    controlledState,
    children,
    toolbars = EditorDefaultActivatedToolbarControls,
    centerToolbars
}, editorRef): ReactElement => {
    console.log('editor render');
    const { t } = useTranslation();
    const dispatch = useDispatch();
    //const [editorState, setEditorState] = useState(EditorState.createEmpty(decorator));
    const [link, setLink] = React.useState('');
    const [textTypeOpen, setTextTypeOpen] = React.useState(false);
    const [textType, setTextType] = React.useState('paragraph');
    const [linkText, setLinkText] = React.useState('');
    const current_locale = useSelector((state: AppState) => state.locale.current_locale);
    const previous_locale = useSelector((state: AppState) => state.locale.previous_locale);
    const quotationCode = useSelector((state: AppState) => state.header.quotation_code);
    const [openPalette, setOpenPalette] = useState(false);
    const styles = {
        ...RichEditorStyleMap,
        ...(quotationCode ? customColors[quotationCode] : null)
    };
    const handleKeyCommand = (command: any): DraftHandleValue => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            return "handled";
        }
        return "not-handled";
    };
    const Image = (props: { src: string | undefined; }) => {
        return <img src={ props.src } width={ 150 }/>;
    };
    const Media = (props: { contentState: { getEntity: (arg0: any) => any; }; block: { getEntityAt: (arg0: number) => any; }; }) => {
        const entity = props.contentState.getEntity(props.block.getEntityAt(0));
        const type = entity.getType();
        let media;
        if (type === 'image') {
            const { src } = entity.getData();
            media = <Image src={ src }/>;
        }
        return media;
    };
    const mediaBlockRenderer = (block: { getType: () => string; }) => {
        if (block.getType() === 'atomic') {
            return {
                component: Media,
                editable: false
            };
        }
    };
    const onToggleTextType = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const element = document.getElementById(`ft-${id}-editorTextTypeOptionContainer`);
        if (element !== undefined && element !== null) {
            if (element.style.display === 'inline-block') {
                element.style.display = 'none';
                setTextTypeOpen(false);
            } else {
                element.style.display = 'inline-block';
                setTextTypeOpen(true);
            }
        }
    };
    const onClickAwayTextType = () => {
        const element = document.getElementById(`ft-${id}-editorTextTypeOptionContainer`);
        if (element !== undefined && element !== null) {
            element.style.display = 'none';
            setTextTypeOpen(false);
        }
    };
    const onTextTypeChange = (type: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleBlockType(editorState, type));
        setTextType(type);
        const element = document.getElementById(`ft-${id}-editorTextTypeOptionContainer`);
        if (element !== undefined && element !== null) {
            element.style.display = 'none';
            setTextTypeOpen(false);
        }
    };
    const onToggleBlockType = (type: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleBlockType(editorState, type));
    };
    const onIndentDecrease = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        const tabIndent = '    ';
        const currentState = editorState;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const selectedText = currentContentBlock.getText();
        if (selectedText.substring(0, 4) === tabIndent) {
            const blockSelection = selectionState.merge({
                anchorOffset: 0,
                focusOffset: selectedText.length
            });
            const nextState = Modifier.replaceText(currentContent, blockSelection, selectedText.substring(4));
            setEditorState(EditorState.push(currentState, nextState, 'indent'));
        }
    };
    const onIndentIncrease = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        //const currentState = editorState;
        //const selection = currentState.getSelection();
        //const blockType = currentState
        //.getCurrentContent()
        //.getBlockForKey(selection.getStartKey())
        //.getType();
        //if (blockType === 'unordered-list-item' || blockType === 'ordered-list-item') {
        //setEditorState(RichUtils.onTab(e, currentState, 3));
        //} else {
        const tabIndent = '    ';
        const currentState = editorState;
        const selectionState = editorState.getSelection();
        const anchorKey = selectionState.getAnchorKey();
        const currentContent = editorState.getCurrentContent();
        const currentContentBlock = currentContent.getBlockForKey(anchorKey);
        const selectedText = currentContentBlock.getText();
        const blockSelection = selectionState.merge({
            anchorOffset: 0,
            focusOffset: selectedText.length
        });
        const nextState = Modifier.replaceText(currentContent, blockSelection, tabIndent + selectedText);
        setEditorState(EditorState.push(currentState, nextState, 'indent'));
        //}
    };
    const onColor = (color: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setOpenPalette(false);
        const currentStyle = editorState.getCurrentInlineStyle();
        console.log('currentStyle : ', currentStyle);
        setEditorState(RichUtils.toggleInlineStyle(editorState, `COLOR_${color}`));
    };
    const onToggleInlineStyle = (style: string) => (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setEditorState(RichUtils.toggleInlineStyle(editorState, style));
    };
    const onAddPicture = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        dispatch(toggleCreate());
    };
    const onTogglePalette = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setOpenPalette((open) => !open);
    };
    const onToggleLink = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const contentState = editorState.getCurrentContent();
            const startKey = selection.getStartKey();
            const anchorKey = selection.getAnchorKey();
            const startOffset = selection.getStartOffset();
            const endOffset = selection.getEndOffset();
            const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
            const currentContentBlock = contentState.getBlockForKey(anchorKey);
            const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
            const selectedText = currentContentBlock.getText().slice(startOffset, endOffset);
            let url = '';
            if (linkKey) {
                const linkInstance = contentState.getEntity(linkKey);
                url = linkInstance.getData().link;
            }
            setLink(url);
            setLinkText(selectedText);
        }
        const element = document.getElementById(`ft-${id}-editorLinkContainer`);
        if (element !== undefined && element !== null) {
            element.style.display = 'inline-block';
        }
    };
    const onClickAwayLink = () => {
        const element = document.getElementById(`ft-${id}-editorLinkContainer`);
        if (element !== undefined && element !== null) {
            element.style.display = 'none';
        }
    };
    const onLinkChange = (event: { target: { value: any; }; }) => {
        setLink(event.target.value);
    };
    const onLinkTextChange = (event: { target: { value: any; }; }) => {
        setLinkText(event.target.value);
    };
    const onAddLink = () => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            {
                link: link,
                text: linkText
            }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const selection = editorState.getSelection();
        const textWithEntity = Modifier.replaceText(
            contentState,
            selection,
            linkText,
            editorState.getCurrentInlineStyle(),
            entityKey
        );
        const newEditorState = EditorState.createWithContent(textWithEntity, decorator);
        const element = document.getElementById(`ft-${id}-editorLinkContainer`);
        if (element !== undefined && element !== null) {
            element.style.display = 'none';
        }
        setEditorState(newEditorState);
        setLink('');
        setLinkText('');
    };
    useEffect(() => {
        if (pictures !== null && pictures.length > 0) {
            //const html = convertToHTML({
            //styleToHTML: (style) => {
            //if (style === 'BOLD') {
            //return <strong/>;
            //} else if (style === 'STRIKETHROUGH') {
            //return <del/>;
            //} else if (style === 'COLOR_RED') {
            //return <span style={{color: 'red'}}/>;
            //} else if (style === 'COLOR_GREEN') {
            //return <span style={{color: 'green'}}/>;
            //} else if (style === 'COLOR_BLUE') {
            //return <span style={{color: 'blue'}}/>;
            //}
            //},
            //blockToHTML: (block) => {
            //if (block.type === 'PARAGRAPH') {
            //return <p/>;
            //} else if (block.type === 'align-left') {
            //return <p style={{textAlign: 'left'}}/>
            //} else if (block.type === 'align-center') {
            //return <p style={{textAlign: 'center'}}/>
            //} else if (block.type === 'align-right') {
            //return <p style={{textAlign: 'right'}}/>
            //} else if (block.type === 'align-justify') {
            //return <p style={{textAlign: 'justify'}}/>
            //}
            //},
            //entityToHTML: (entity, originalText) => {
            //if (entity.type === 'LINK') {
            //return <a href={entity.data.url}>{originalText}</a>;
            //} else if (entity.type === 'image') {
            //console.log('entity : ', entity);
            //return <img src={ entity.data.src }/>
            //}
            //return originalText;
            //}
            //})(editorState.getCurrentContent());
            //console.log('html : ', html);


            const contentState = editorState.getCurrentContent();
            const contentStateWithEntity = contentState.createEntity(
                'image',
                'MUTABLE',
                {
                    src: pictures?.[0].url,
                    alt: pictures?.[0].name !== null ? pictures?.[0].name : '',
                    alignment: 'default'
                }
            );
            const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
            const newEditorState = EditorState.set(
                editorState,
                { currentContent: contentStateWithEntity }
            );
            setEditorState(AtomicBlockUtils.insertAtomicBlock(
                newEditorState,
                entityKey,
                ' '
            ));
        }
    }, [pictures]);
    const onSave = (save_locale: null | number) => () => {
        if (save_locale !== null) {
            const rawContentState = convertToRaw(editorState.getCurrentContent());
            console.log('editorState : ', editorState);
            console.log('editorState.getCurrentContent() : ', editorState.getCurrentContent());
            console.log('rawContentState : ', rawContentState);
            //const html = draftToHtml(rawContentState, {}, false, customEntityTransform);

            const html = convertToHTML({
                styleToHTML: (style, text): Tag => {
                    console.log('style : ', style);
                    console.log('text : ', text);
                    if (style === 'BOLD') {
                        return <strong/>;
                    } else if (style === 'STRIKETHROUGH') {
                        return <del/>;
                    } else if (style.includes('COLOR_')) {
                        return <span style={{ color: styles[style].color }}/>;
                    }
                },
                blockToHTML: (block) => {
                    console.log('block : ', block);
                    if (block.type === 'PARAGRAPH') {
                        return <p/>;
                    } else if (block.type === 'align-left') {
                        return <p style={{ textAlign: 'left' }}/>;
                    } else if (block.type === 'align-center') {
                        return <p style={{ textAlign: 'center' }}/>;
                    } else if (block.type === 'align-right') {
                        return <p style={{ textAlign: 'right' }}/>;
                    } else if (block.type === 'align-justify') {
                        return <p style={{ textAlign: 'justify' }}/>;
                    }
                },
                entityToHTML: (entity, originalText) => {
                    console.log('entity : ', entity);
                    console.log('originalText : ', originalText);
                    if (entity.type === 'LINK') {
                        return <a href={ entity.data.link } target={ 'blank' }>{ entity.data.text }</a>;
                    } else if (entity.type === 'image') {
                        return <img src={ entity.data.src }/>;
                    }
                    return originalText;
                }
            })(editorState.getCurrentContent());

            if (html.replace(/<[^>]+>/g, '') !== '\n' && action) {
                console.log('html : ', html);
                dispatch(action(save_locale, html));
            }
        }
    };
    const onUndo = () => {
        setEditorState(EditorState.undo(editorState));
    };
    const onRedo = () => {
        setEditorState(EditorState.redo(editorState));
    };

    const convertToRGB = (hex: string) => {
        const r = "0x" + hex[1] + hex[2];
        const g = "0x" + hex[3] + hex[4];
        const b = "0x" + hex[5] + hex[6];
        return `rgb(${+r}, ${+g}, ${+b})`;
    };
    useEffect(() => {
        if (current_locale !== null && !controlledState) {
            if (values[current_locale]) {
                //const contentState = convertFromHTML({
                //htmlToStyle: (nodeName, node, currentStyle) => {
                //if (nodeName === 'span' && node.style.color === 'blue') {
                //return currentStyle.add('BLUE');
                //} else {
                //return currentStyle;
                //}
                //},
                //htmlToEntity: (nodeName, node, createEntity) => {
                //if (nodeName === 'a') {
                //return createEntity(
                //'LINK',
                //'MUTABLE',
                //{url: node.href}
                //)
                //}
                //},
                //textToEntity: (text, createEntity) => {
                //const result = [];
                //text.replace(/\@(\w+)/g, (match, name, offset) => {
                //const entityKey = createEntity(
                //'AT-MENTION',
                //'IMMUTABLE',
                //{name}
                //);
                //result.push({
                //entity: entityKey,
                //offset,
                //length: match.length,
                //result: match
                //});
                //});
                //return result;
                //},
                //htmlToBlock: (nodeName, node) => {
                //if (nodeName === 'blockquote') {
                //return {
                //type: 'blockquote',
                //data: {}
                //};
                //}
                //}
                //})(values[current_locale]);


                const contentState = convertFromHTML({
                    htmlToStyle: (nodeName, node, currentStyle) => {
                        if (nodeName === 'span' && !!node.style.color) {
                            let color = '';
                            for (const [key, value] of Object.entries(styles)) {
                                if (key.includes('COLOR_') && convertToRGB(value.color) === node.style.color) {
                                    color = key;
                                }
                            }
                            if (color !== '') {
                                return currentStyle.add(color);
                            } 
                            return currentStyle;
                        } 
                        return currentStyle;
                    },
                    //htmlToStyle: (nodeName, node, currentStyle) => {
                    //console.log('nodeName : ', nodeName);
                    //console.log('node : ', node);
                    //console.log('currentStyle : ', currentStyle);
                    //// if (nodeName === 'span' && !!node.style.color) {
                    ////     for (const [key, value] of Object.entries(styleMap)) {
                    ////         console.log('key : ', key);
                    ////         console.log('value : ', value);
                    ////         if (key.includes('COLOR_') && value === node.style.color){
                    ////             return currentStyle.add(key);
                    ////         }
                    ////     }
                    //// }
                    //},
                    htmlToEntity: (nodeName, node, createEntity) => {
                        if (nodeName === 'a') {
                            const entityConfig = {};
                            entityConfig.link = node.href ? node.getAttribute('href') || node.href : node.href;
                            const doc = new DOMParser().parseFromString(node.textContent, "text/html");
                            //return doc.documentElement.textContent;
                            //console.log('node.innerHTML : ', node.innerHTML);
                            //console.log('node.textContent : ', doc.documentElement.textContent);
                            entityConfig.text = doc.documentElement.textContent;
                            node.textContent = doc.documentElement.textContent;
                            return createEntity(
                                'LINK',
                                'MUTABLE',
                                entityConfig
                            );
                        }
                        if (nodeName === 'img') {
                            const entityConfig = {};
                            entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
                            entityConfig.alt = node.alt;
                            entityConfig.height = node.style.height;
                            entityConfig.width = node.style.width;

                            return createEntity(
                                'IMAGE',
                                'MUTABLE',
                                entityConfig
                            );
                        }
                    },
                    htmlToBlock: (nodeName, node) => {
                        if (nodeName === 'p' && !!node.style.textAlign) {
                            return {
                                type: `align-${node.style.textAlign}`,
                                data: {}
                            };
                        }
                        if (nodeName === 'blockquote') {
                            return {
                                type: 'blockquote',
                                data: {}
                            };
                        }
                    }
                })(values[current_locale]);
                //const blocksFromHtml = htmlToDraft(values[current_locale]);
                //const { contentBlocks, entityMap } = blocksFromHtml;
                //const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
                setEditorState(EditorState.createWithContent(contentState, decorator));
            } else {
                setEditorState(EditorState.createEmpty(decorator));
            }
            onSave(previous_locale);
            //const html = convertToHTML({
            //styleToHTML: (style) => {
            //if (style === 'BOLD') {
            //return <strong/>;
            //} else if (style === 'STRIKETHROUGH') {
            //return <del/>;
            //} else if (style === 'COLOR_RED') {
            //return <span style={{color: 'red'}}/>;
            //} else if (style === 'COLOR_GREEN') {
            //return <span style={{color: 'green'}}/>;
            //} else if (style === 'COLOR_BLUE') {
            //return <span style={{color: 'blue'}}/>;
            //}
            //},
            //blockToHTML: (block) => {
            //if (block.type === 'PARAGRAPH') {
            //return <p/>;
            //} else if (block.type === 'align-left') {
            //return <p style={{textAlign: 'left'}}/>
            //} else if (block.type === 'align-center') {
            //return <p style={{textAlign: 'center'}}/>
            //} else if (block.type === 'align-right') {
            //return <p style={{textAlign: 'right'}}/>
            //} else if (block.type === 'align-justify') {
            //return <p style={{textAlign: 'justify'}}/>
            //}
            //},
            //entityToHTML: (entity, originalText) => {
            //if (entity.type === 'LINK') {
            //return <a href={ entity.data.url } target={ 'blank' }>{ originalText }</a>;
            //} else if (entity.type === 'image') {
            //console.log('entity : ', entity);
            //return <img src={ entity.data.src }/>
            //}
            //return originalText;
            //}
            //})(editorState.getCurrentContent());
            //const fromHtml = convertFromHTML({
            //htmlToStyle: (nodeName, node, currentStyle) => {
            //if (nodeName === 'span' && node.style.color === 'blue') {
            //return currentStyle.add('BLUE');
            //} else {
            //return currentStyle;
            //}
            //},
            //htmlToEntity: (nodeName, node, createEntity) => {
            //if (nodeName === 'a') {
            //return createEntity(
            //'LINK',
            //'MUTABLE',
            //{url: node.href}
            //)
            //}
            //},
            //textToEntity: (text, createEntity) => {
            //const result = [];
            //text.replace(/\@(\w+)/g, (match, name, offset) => {
            //const entityKey = createEntity(
            //'AT-MENTION',
            //'IMMUTABLE',
            //{name}
            //);
            //result.push({
            //entity: entityKey,
            //offset,
            //length: match.length,
            //result: match
            //});
            //});
            //return result;
            //},
            //htmlToBlock: (nodeName, node) => {
            //if (nodeName === 'blockquote') {
            //return {
            //type: 'blockquote',
            //data: {}
            //};
            //}
            //}
            //})(values[current_locale]);
        }
    }, [current_locale]);
    const selection = editorState.getSelection();
    const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
    const currentStyle = editorState.getCurrentInlineStyle();

    return (
        <Grid container>
            <Grid
                className={ 'ft-editorButtonContainer' }
                item
                container
                sx={{
                    display: hideToolbar ? 'none' : 'flex',
                    justifyContent: centerToolbars ? 'center' : 'flex-start'
                }}
            >
                {
                    toolbars.includes('text-type') &&
                    <ClickAwayListener onClickAway={ onClickAwayTextType }>
                        <div className={ 'ft-editorTextTypeContainer' }>
                            <Button className={ 'ft-editorTextType' } onMouseDown={ onToggleTextType } color={ 'inherit' } endIcon={ textTypeOpen ? <KeyboardArrowUp/> : <KeyboardArrowDown/> }>
                                { t(`shared.${textType}`) }
                            </Button>
                            <div id={ `ft-${id}-editorTextTypeOptionContainer` } className={ 'ft-editorTextTypeOptionContainer' }>
                                <p className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('paragraph') }>{ t('shared.paragraph') }</p>
                                <h6 className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('header-six') }>{ t('shared.header-six') }</h6>
                                <h5 className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('header-five') }>{ t('shared.header-five') }</h5>
                                <h4 className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('header-four') }>{ t('shared.header-four') }</h4>
                                <h3 className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('header-three') }>{ t('shared.header-three') }</h3>
                                <h2 className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('header-two') }>{ t('shared.header-two') }</h2>
                                <h1 className={ 'ft-editorTextTypeOption' } onMouseDown={ onTextTypeChange('header-one') }>{ t('shared.header-one') }</h1>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
                {
                    toolbars.includes('font-size') &&
                    <RichEditorFontSizeControl
                        editorState={editorState}
                        setEditorState={setEditorState}
                    />
                }
                {
                    toolbars.includes('color') &&
                    <ClickAwayListener onClickAway={() => setOpenPalette(false)}>
                        <div style={{ position: 'relative' }}>
                            <IconButton disableRipple onMouseDown={ onTogglePalette }>
                                <Palette/>
                            </IconButton>
                            <Grid
                                id={ `ft-${id}-editorPaletteColorContainer` }
                                className={ 'ft-editorPaletteColorContainer' }
                                sx={(theme) => ({
                                    display: openPalette ? 'inline-block' : 'none',
                                    zIndex: theme.zIndex.modal + 1
                                })}
                                container
                                spacing={ 1 }
                            >
                                <Grid item container spacing={ 1 }>
                                    {
                                        Object.keys(styles).filter((key) => {
                                            return key.startsWith('COLOR');
                                        }).map((key) => {
                                            return (
                                                <Grid key={key} item>
                                                    <div
                                                        style={{ backgroundColor: styles[key]?.color }}
                                                        className={ 'ft-editorPaletteColor' }
                                                        onMouseDown={ onColor(key.replace('COLOR_', '')) }
                                                    />
                                                </Grid>
                                            );
                                        })
                                    }
                                </Grid>
                            </Grid>
                        </div>
                    </ClickAwayListener>
                }
                {
                    toolbars.includes('alignment') &&
                    <>
                        <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-left' }) } disableRipple onMouseDown={ onToggleBlockType('align-left') }>
                            <FormatAlignLeft/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-center' }) } disableRipple onMouseDown={ onToggleBlockType('align-center') }>
                            <FormatAlignCenter/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-right' }) } disableRipple onMouseDown={ onToggleBlockType('align-right') }>
                            <FormatAlignRight/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'align-justify' }) } disableRipple onMouseDown={ onToggleBlockType('align-justify') }>
                            <FormatAlignJustify/>
                        </IconButton>
                    </>
                }
                {
                    toolbars.includes('indent') &&
                    <>
                        <IconButton disableRipple onMouseDown={ onIndentDecrease }>
                            <FormatIndentDecrease/>
                        </IconButton>
                        <IconButton disableRipple onMouseDown={ onIndentIncrease }>
                            <FormatIndentIncrease/>
                        </IconButton>
                    </>
                }
                {
                    toolbars.includes('link') &&
                    <ClickAwayListener onClickAway={ onClickAwayLink }>
                        <div style={{ position: 'relative' }}>
                            <IconButton disableRipple onMouseDown={ onToggleLink }>
                                <AddLink/>
                            </IconButton>
                            <div id={ `ft-${id}-editorLinkContainer` } className={ 'ft-editorLinkContainer' }>
                                <Grid container spacing={ 2 } justifyContent={ 'flex-end' }>
                                    <Grid item xs={ 12 }>
                                        <FormControl fullWidth required variant={ 'outlined' }>
                                            <InputLabel htmlFor={ `editor-${id}-link` }>{ t('shared.link') }</InputLabel>
                                            <OutlinedInput
                                                id={ `editor-${id}-link` }
                                                type={ 'text' }
                                                onChange={ onLinkChange }
                                                label={ t('shared.link') }
                                                value={ link }
                                                autoComplete={ 'off' }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={ 12 }>
                                        <FormControl fullWidth required variant={ 'outlined' }>
                                            <InputLabel htmlFor={ `editor-${id}-link-text` }>{ t('shared.link-text') }</InputLabel>
                                            <OutlinedInput
                                                id={ `editor-${id}-link-text` }
                                                type={ 'text' }
                                                onChange={ onLinkTextChange }
                                                label={ t('shared.link-text') }
                                                value={ linkText }
                                                autoComplete={ 'off' }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item>
                                        <Button onClick={ onAddLink }>{ t('shared.validate') }</Button>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </ClickAwayListener>
                }
                {
                    toolbars.includes('format') &&
                    <>
                        <Divider orientation={ 'vertical' } flexItem/>
                        <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('BOLD') }) } disableRipple onMouseDown={ onToggleInlineStyle('BOLD') }>
                            <FormatBold/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('ITALIC') }) } disableRipple onMouseDown={ onToggleInlineStyle('ITALIC') }>
                            <FormatItalic/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('UNDERLINE') }) } disableRipple onMouseDown={ onToggleInlineStyle('UNDERLINE') }>
                            <FormatUnderlined/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: currentStyle.has('STRIKETHROUGH') }) } disableRipple onMouseDown={ onToggleInlineStyle('STRIKETHROUGH') }>
                            <StrikethroughS/>
                        </IconButton>
                    </>
                }
                {
                    toolbars.includes('image') &&
                    <>
                        <Divider orientation={ 'vertical' } flexItem/>
                        <IconButton disableRipple onMouseDown={ onAddPicture }>
                            <PhotoLibrary/>
                        </IconButton>
                    </>
                }
                {
                    toolbars.includes('lists') &&
                    <>
                        <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'unordered-list-item' }) } disableRipple onMouseDown={ onToggleBlockType('unordered-list-item') }>
                            <FormatListBulleted/>
                        </IconButton>
                        <IconButton className={ clsx({ ['ft-editorActive']: blockType === 'ordered-list-item' }) } disableRipple onMouseDown={ onToggleBlockType('ordered-list-item') }>
                            <FormatListNumbered/>
                        </IconButton>
                    </>
                }
                {
                    toolbars.includes('undo') &&
                    <>
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title={`${t('shared.undo')} (Ctrl + z)`}>
                            <IconButton onMouseDown={onUndo} disableRipple>
                                <UndoIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`${t('shared.redo')} (Ctrl + y)`}>
                            <IconButton onMouseDown={onRedo} disableRipple>
                                <RedoIcon />
                            </IconButton>
                        </Tooltip>
                    </>
                }
            </Grid>
            <Grid
                item
                xs={ 12 }
            >
                {
                    children ?
                        children(
                            <Editor
                                customStyleMap={{
                                    ...styles,
                                    ...RichEditorFontSizeStylesMap
                                }}
                                blockRendererFn={ mediaBlockRenderer }
                                blockRenderMap={ extendedBlockRenderMap }
                                //keyBindingFn={ keyDown }
                                editorState={ editorState }
                                handleKeyCommand={ handleKeyCommand }
                                onChange={ setEditorState }
                                ref={ editorRef }
                            />
                        ) :
                        <div
                            className={ 'ft-editor' }
                            onBlur={ onSave(current_locale) }
                            style={{
                                ...(
                                    noDefault ?
                                        {
                                            padding: 0,
                                            border: 'none',
                                            minHeight: 0
                                        } :
                                        undefined
                                ),
                                ...contentContainerStyles
                            }}
                        >
                            <Editor
                                customStyleMap={{
                                    ...styles,
                                    ...RichEditorFontSizeStylesMap
                                }}
                                blockRendererFn={ mediaBlockRenderer }
                                blockRenderMap={ extendedBlockRenderMap }
                                //keyBindingFn={ keyDown }
                                editorState={ editorState }
                                handleKeyCommand={ handleKeyCommand }
                                onChange={ setEditorState }
                                ref={ editorRef }
                            />
                        </div>
                }
            </Grid>
        </Grid>
    );
});

export default RichEditor;
