export default (ruleName: string) => {
	let result = null;
	let find = Array.prototype.find;
	const styleSheets = Array.from(document.styleSheets).filter(
		(styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
	);
	find.call(styleSheets, styleSheet => {
		if (styleSheet.cssRules) {
			result = find.call(styleSheet.cssRules, cssRule => {
				return cssRule instanceof CSSStyleRule
					&& cssRule.selectorText.toLowerCase() == ruleName;
			});
			return result != null;
		}
	});
	return result;
};