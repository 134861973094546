import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { Box, styled, Typography } from "@mui/material";
import { clone } from "lodash";
import { MailTemplateVisualEditorImageOption } from "../mailTemplateVisualEditorImageOption";
import { MailTemplateVisualEditorSizeOption } from "../mailTemplateVisualEditorSizeOption";
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import { MailTemplateVisualEditorBorderColorOption } from "../mailTemplateVisualEditorBorderColorOption";
import { MailTemplateVisualEditorBorderCornersOption, MailTemplateVisualEditorCornersOption } from "../mailTemplateVisualEditorBorderCornersOption";
import { MailTemplateVisualEditorBorderStyle, MailTemplateVisualEditorBorderStyleOption } from "../mailTemplateVisualEditorBorderStyleOption";
import { MailTemplateVisualEditorBorderWidthOption } from "../mailTemplateVisualEditorBorderWidthOption";
import { MailTemplateVisualEditorPaddingOptions } from "../mailTemplateVisualEditorPaddingOptions";
import { Alignment, MailTemplateVisualEditorAlignmentOption } from "../mailTemplateVisualEditorAlignmentOption";
import { MailTemplateVisualEditorLinkUrlOption } from "../mailTemplateVisualEditorLinkUrlOption";
import { MailTemplateVariablePicker } from "../mailTemplateVariablePicker";
import { registerBlock } from "../utils/registered-blocks";
import { generateBlockId } from "../utils/block-id";
import { setVisualEditorBlockOptions } from "../redux/actions";
import { AutocompleteTranslations } from "../objects/autocompleteTranslations";
import { Block } from "../objects/block";
import { BlockHtmlAttribute } from "../objects/blockHtmlAttributes";
import { AppState } from "../../../../../Reducers/Reducers";
import Icon from '@mui/icons-material/Photo';

type Options = {
    alignment: Alignment,
    width: number,
    url: string | null,
    linkUrl: string | null,
    backgroundColor: string,
    borderWidth: number,
    borderStyle: MailTemplateVisualEditorBorderStyle,
    borderColor: string,
    borderCorners: MailTemplateVisualEditorCornersOption,
    padding: MailTemplateVisualEditorPaddingOptions
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}

function MailTemplateVisualEditorImageBlock(props: Props): JSX.Element {
    const { t } = useTranslation();
    const autocomplete = useSelector((state: AppState) => state.mailTemplate.others.autocomplete);
    const translations = autocomplete.state === 'success' ?
        autocomplete.data.translations :
        { action: {}, fields: {}, recipients: {} } as AutocompleteTranslations;

    const translateVariable = (variable: string) => {
        const value = translations.fields[variable] ?? {};
        return value[i18next.language] ?? Object.values(value)?.[0] ?? variable;
    };

    if (props.options.url === null) {
        return (
            <NoImageContainer>
                <Icon fontSize="large" />
                <Typography>
                    {t<string>('shared.mail-template-visual-editor-no-image')}
                </Typography>
            </NoImageContainer>
        );
    } else if (
        props.options.url &&
        props.options.url.trim().length > 0 &&
        !props.options.url.startsWith('http')
    ) {
        return (
            <NoImageContainer>
                <Icon fontSize="large" />
                <Typography sx={{ textAlign: 'center' }}>
                    {t<string>('shared.mail-template-visual-editor-image-variable')}: {' '}
                    {translateVariable(props.options.url)}
                </Typography>
            </NoImageContainer>
        );
    }
    return (
        <Box
            sx={{
                lineHeight: 0,
                backgroundColor: props.options.backgroundColor,
                paddingTop: props.options.padding.top + 'px',
                paddingBottom: props.options.padding.bottom + 'px',
                paddingLeft: props.options.padding.left + 'px',
                paddingRight: props.options.padding.right + 'px',
                textAlign: props.options.alignment
            }}
        >
            <img
                src={props.options.url}
                width={props.options.width + 'px'}
                height="auto"
                style={{
                    width: props.options.width + 'px',
                    height: props.options.borderCorners.rounded ?
                        props.options.width + 'px' :
                        'auto',
                    objectFit: props.options.borderCorners.rounded ? 'cover' : undefined,
                    maxWidth: '100%',
                    borderStyle: props.options.borderStyle,
                    borderWidth: `${props.options.borderWidth}px`,
                    borderColor: props.options.borderColor,
                    borderRadius: props.options.borderCorners.rounded ?
                        '50%' :
                        `
                            ${props.options.borderCorners.topLeft}px
                            ${props.options.borderCorners.topRight}px
                            ${props.options.borderCorners.bottomRight}px
                            ${props.options.borderCorners.bottomLeft}px
                        `
                }}
            />
        </Box>
    );
}

const NoImageContainer = styled('div')((props) => ({
    "display": 'flex',
    "flexDirection": 'column',
    "justifyContent": 'center',
    "alignItems": 'center',
    "width": '100%',
    "height": 200,
    "backgroundColor": '#4A90E2',
    "border": '2px solid #4A90F2',
    "color": props.theme.palette.getContrastText('#4A90E2'),
    '& svg': {
        color: props.theme.palette.getContrastText('#4A90E2')
    }
}));

function MailTemplateVisualEditorImageBlockOptions(props: OptionsComponentProps): JSX.Element {
    const locale = useSelector((state: AppState) => state.locale.current_locale);
    const dispatch = useDispatch();

    const onChangeOption = (key: keyof OptionsComponentProps["options"], value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                {
                    ...props.options,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    };

    function onChangeCorner<K extends keyof Options["borderCorners"]>(
        type: K,
        value: Options["borderCorners"][K]
    ): void {
        onChangeOption("borderCorners", { ...props.options.borderCorners, [type]: value });
    }

    const onChangePadding = (type: keyof Options["padding"], value: number) => {
        onChangeOption("padding", { ...props.options.padding, [type]: value });
    };

    return (
        <div>
            <MailTemplateVariablePicker
                onChoose={(variable) => onChangeOption('url', `{{${variable}}}`)}
            />
            <MailTemplateVisualEditorImageOption
                url={props.options.url}
                onChangeUrl={(url) => onChangeOption('url', url)}
            />
            <MailTemplateVisualEditorSizeOption
                size={props.options.width}
                onChangeSize={(value) => onChangeOption('width', value)}
                max={800}
            />
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption('backgroundColor', color)}
            />
            <MailTemplateVisualEditorAlignmentOption
                alignment={props.options.alignment}
                onChangeAlignment={(alignment) => onChangeOption("alignment", alignment)}
            />
            <MailTemplateVisualEditorLinkUrlOption
                url={props.options.linkUrl}
                onChangeUrl={(url) => onChangeOption('linkUrl', url)}
            />
            <MailTemplateVisualEditorBorderWidthOption
                width={props.options.borderWidth}
                onChangeWidth={(width) => onChangeOption("borderWidth", width)}
            />
            <MailTemplateVisualEditorBorderStyleOption
                style={props.options.borderStyle}
                onChangeStyle={(style) => onChangeOption("borderStyle", style)}
            />
            <MailTemplateVisualEditorBorderColorOption
                color={props.options.borderColor}
                onChangeColor={(color) => onChangeOption("borderColor", color)}
            />
            <MailTemplateVisualEditorBorderCornersOption
                corners={props.options.borderCorners}
                onChangeCorner={onChangeCorner}
                canBeRounded
            />
            <MailTemplateVisualEditorPaddingOptions
                padding={props.options.padding}
                onChangePadding={onChangePadding}
            />
        </div>
    );
}

export class ImageBlock implements Block<Options> {
    private id;
    private options: Options;


    public constructor() {
        this.id = generateBlockId();
        this.options = {
            alignment: 'left',
            width: 800,
            url: null,
            linkUrl: null,
            backgroundColor: '#fff',
            borderWidth: 0,
            borderColor: '#000',
            borderStyle: 'solid',
            borderCorners: {
                topLeft: 0,
                topRight: 0,
                bottomRight: 0,
                bottomLeft: 0
            },
            padding: {
                top: 0,
                bottom: 0,
                left: 0,
                right: 0
            }
        };
    }

    public getType(): string {
        return 'image';
    }
    public getId(): number {
        return this.id;
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public getExtraHtmlAttributes(): BlockHtmlAttribute[] {
        return [
            {
                path: `.image-block.${this.id}`,
                name: 'data-url',
                content: this.options.url ?? ''
            },
            {
                path: `.image-block.${this.id}`,
                name: 'data-link-url',
                content: this.options.linkUrl ?? ''
            }
        ];
    }

    public renderMjml(): string {
        const options = this.getOptions();
        if (!options.url) {
            return '';
        }
        const height = options.borderCorners.rounded ?
            options.width + 'px' :
            'auto';
        const borderRadius = options.borderCorners.rounded ?
            options.width / 2 + 'px' :
            `${options.borderCorners.topLeft}px ${options.borderCorners.topRight}px ${options.borderCorners.bottomRight}px ${options.borderCorners.bottomLeft}px`;
        const className = [
            'image-block',
            this.id,
            options.borderCorners.rounded ? 'rounded' : null
        ].filter((item) => item).join(' ');
        return `
            <mj-image
                align="${options.alignment}"
                width="${options.width}px"
                height="${height}"
                alt="${options.url}"
                src="${options.url}"
                href="${options.linkUrl ?? ''}"
                container-background-color="${options.backgroundColor}"
                padding-top="${options.padding.top}px"
                padding-bottom="${options.padding.bottom}px"
                padding-left="${options.padding.left}px"
                padding-right="${options.padding.right}px"
                border="${options.borderWidth}px ${options.borderStyle} ${options.borderColor}"
                border-radius="${borderRadius}"
                css-class="${className}"
            />
        `;
    }

    public findBlock(id: number): ImageBlock | undefined {
        if (id === this.getId()) {
            return this;
        }
    }

    public clone(options?: Options): ImageBlock {
        let block: ImageBlock;

        if (options) {
            block = clone(this);
        } else {
            block = new ImageBlock();
        }

        const blockOptions = options ?? this.getOptions();
        block.setOptions(blockOptions);

        return block;
    }
}

registerBlock({
    type: "image",
    icon: <Icon />,
    label: "shared.mail-template-visual-editor-image-block-label",
    component: MailTemplateVisualEditorImageBlock,
    optionsComponent: MailTemplateVisualEditorImageBlockOptions,
    htmlAttributes: [],
    styles: [
        `
            .image-block.rounded img {
                object-fit: cover;
            }
        `
    ],
    factory: () => new ImageBlock()
});
