import { combineReducers } from 'redux';
import home from './component/home/redux/reducer';
import trip from './component/trip/redux/reducer';
import login from './component/login/redux/reducer';
import event from './component/event/redux/reducer';
import folder from './component/folder/redux/reducer';
import header from './component/header/redux/reducer';
import flight from './component/flight/redux/reducer';
import footer from './component/footer/redux/reducer';
import locale from './component/locale/redux/reducer';
import payment from './component/payment/redux/reducer';
import profile from './component/profile/redux/reducer';
import provider from './component/provider/redux/reducer';
import currency from './component/currency/redux/reducer';
import loading from './component/utils/loading/redux/reducer';
import finished from './component/utils/finished/redux/reducer';
import accommodation from './component/accommodation/redux/reducer';
import reset_password from './component/resetPassword/redux/reducer';
import cart from './component/cartRecode/redux/reducer';
import picture from './component/picture/redux/reducer';
import mailTemplate from './component/trip/MailVisualEditor/redux/reducer';
import clientService from './component/clientServices/redux/reducer';
export const rootReducer = combineReducers({
    home,
    payment,
    trip,
    login,
    event,
    header,
    flight,
    footer,
    locale,
    profile,
    loading,
    provider,
    currency,
    finished,
    accommodation,
    reset_password,
    [cart.name]: cart.reducer,
    [folder.name]: folder.reducer,
    mailTemplate,
    picture,
    flight,
    clientService
});

export type AppState = ReturnType<typeof rootReducer>