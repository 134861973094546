import React from "react";
import { ContentState, convertToRaw } from "draft-js";
import { convertToHTML } from 'draft-convert';

export function renderRichEditorContentHTML(
    styles: {[index: string]: React.CSSProperties},
    content: ContentState
): string {
    const rawContentState = convertToRaw(content);
    console.log('editorState.getCurrentContent() : ', content);
    console.log('rawContentState : ', rawContentState);

    const html = convertToHTML({
        styleToHTML: (style: string) => {
            if (style === 'BOLD') {
                return <strong/>;
            } else if (style === 'STRIKETHROUGH') {
                return <del/>;
            } else if (
                style.includes('COLOR_')
            ) {
                return (
                    <span
                        style={{
                            color: styles[style as keyof Omit<typeof styles, 'STRIKETHROUGH'>]?.color
                        }}
                    />
                );
            } else if (style.includes('FONTSIZE_')) {
                return (
                    <span
                        style={{
                            fontSize: styles[style as keyof typeof styles]?.fontSize
                        }}
                    />
                );
            }
        },
        blockToHTML: (block) => {
            if (block.type === 'PARAGRAPH') {
                return <p/>;
            } else if (block.type === 'align-left') {
                return <p style={{ textAlign: 'left' }}/>;
            } else if (block.type === 'align-center') {
                return <p style={{ textAlign: 'center' }}/>;
            } else if (block.type === 'align-right') {
                return <p style={{ textAlign: 'right' }}/>;
            } else if (block.type === 'align-justify') {
                return <p style={{ textAlign: 'justify' }}/>;
            }
        },
        entityToHTML: (entity, originalText) => {
            if (entity.type === 'LINK') {
                return <a href={ entity.data.link } target={ 'blank' }>{ entity.data.text }</a>;
            } else if (entity.type === 'image') {
                return <img src={ entity.data.src }/>;
            }
            return originalText;
        }
    })(content);

    if (html.replace(/<[^>]+>/g, '') !== '\n') {
        return html;
    }

    return "";
}
