// Dependencies
import * as Sentry from '@sentry/react';
import { SnackbarProvider, useSnackbar } from 'notistack';
import { createRoot } from 'react-dom/client';
import React, { lazy, Suspense } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserTracing } from '@sentry/tracing';
import CssBaseline from '@mui/material/CssBaseline';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
// Components
import PublicRoute from './utils/publicRoute';
import PrivateRoute from './utils/privateRoute';
import Loading from './component/loading/loading';

// Utils
import './index.css';
import store from './store';
import i18n from './translation/i18n';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

// Trip
const TripInfo = lazy(() => import('./component/trip/tripInfo'));
// Login
const Login = lazy(() => import('./component/login/login'));
// Reset Password
const ResetPassword = lazy(() => import('./component/resetPassword/resetPassword'));
// NotFound
const NotFound = lazy(() => import('./component/notFound/notFound'));
// Home
const Home = lazy(() => import('./component/home/home'));
// Accommodation
const Accommodation = lazy(() => import('./component/accommodation/accommodation'));
const AccommodationList = lazy(() => import('./component/accommodation/accommodationList'));
// Cart
const CartRecode = lazy(() => import('./component/cartRecode/cartRecode'));
const Cart = lazy(() => import('./component/cart/cart'));
const CartPDFPreview = lazy(() => import('./component/cart/cartPDFPreview'));
const CartPDFPreviewPurchase = lazy(() => import('./component/cart/cartPDFPreviewPurchase'));
// Payement
const PaymentError = lazy(() => import('./component/payment/paymentError'));
const PaymentRetry = lazy(() => import('./component/payment/paymentRetry'));
const PaymentSuccess = lazy(() => import('./component/payment/paymentSuccess'));
// Folder
const Folder = lazy(() => import('./component/folder/folderList'));
// Profile
const Profile = lazy(() => import('./component/profile/profile'));
// TripList
const TripList = lazy(() => import('./component/trip/tripList'));
// Flights
const FlightList = lazy(() => import('./component/flight/flightList'));
const FlightSelected = lazy(() => import('./component/flight/flightSelected'));
const FlightPaymentSuccess = lazy(() => import('./component/flight/flightPaymentSuccess'));
// Client Services
const ClientServices = lazy(() => import('./component/clientServices/clientServices'));
const ClientList = lazy(() => import('./component/clientServices/clientList'));
const container = document.getElementById('app');
const root = createRoot(container!);

if (window.location.hostname === 'resa.tripmci.com') {
    Sentry.init({
        dsn: 'https://46a03e1c2d3645e0b7c1ebb6b64a053f@o161232.ingest.sentry.io/4504499482394624',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}
const dismissAction = (id:any) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton onClick={() => closeSnackbar(id)}>
            <Close style={{color: 'white'}}/>
        </IconButton>
    );
};

root.render(
    <ReduxProvider store={ store }>
        <I18nextProvider i18n={ i18n }>
            <SnackbarProvider
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                action={(snackbarId) => dismissAction(snackbarId)}
            >
                <CssBaseline/>
                <Suspense fallback={ <Loading/> }>
                    <BrowserRouter basename={ WP_BASE_HREF }>
                        <Routes>
                            <Route path={ '/:slug' } element={ <PublicRoute component={ Home }/> }/>
                            <Route path={ '/:slug/accommodation' } element={ <PublicRoute component={ AccommodationList }/> }/>
                            <Route path={ '/:slug/accommodation/:id' } element={ <PublicRoute component={ Accommodation }/> }/>
                            {/*<Route path={ '/:slug/cart' } element={ <PrivateRoute component={ Cart }/> }/>*/}
                            <Route path={ '/:slug/cart' } element={ <PrivateRoute component={ CartRecode }/> }/>
                            <Route path={ '/:slug/contract-preview' } element={ <PublicRoute component={ CartPDFPreview }/> }/>
                            <Route path={ '/:slug/purchase-preview' } element={ <PublicRoute component={ CartPDFPreviewPurchase }/> }/>
                            <Route path={ '/:slug/login' } element={ <PrivateRoute component={ Login }/> }/>
                            <Route path={ '/:slug/forgot-password' } element={ <PublicRoute component={ Login }/> }/>
                            <Route path={ '/:slug/reset-password' } element={ <PublicRoute component={ Home }/> }/>
                            <Route path={ '/:slug/my-trip' } element={ <PublicRoute component={ TripInfo }/> }/>
                            <Route path={ '/:slug/not-found' } element={ <PublicRoute component={ NotFound }/> }/>
                            <Route path={ '/:slug/payment/retry' } element={ <PrivateRoute component={ PaymentRetry }/> }/>
                            <Route path={ '/:slug/payment/error' } element={ <PrivateRoute component={ PaymentError }/> }/>
                            <Route path={ '/:slug/payment/success/' } element={ <PrivateRoute component={ PaymentSuccess }/> }/>
                            <Route path={ '/:slug/my-folder' } element={ <PublicRoute component={ Folder }/> }/>
                            <Route path={ '/:slug/trip-list' } element={ <PublicRoute component={ TripList }/> }/>
                            <Route path={ '/:slug/profile' } element={ <PublicRoute component={ Profile }/> }/>
                            <Route path={ '/:slug/flight-list' } element={ <PublicRoute component={ FlightList }/> }/>
                            <Route path={ '/:slug/flight-selected' } element={ <PublicRoute component={ FlightSelected }/> }/>
                            <Route path={ '/:slug/flight/payment/success/:trip_id' } element={ <PublicRoute component={ FlightPaymentSuccess }/> }/>
                            <Route path={ '/:slug/my-services' } element={ <PublicRoute component={ ClientServices }/> }/>
                            <Route path={ '/:slug/client-services' } element={ <PublicRoute component={ ClientList }/> }/>
                            <Route path={ '/*' } element={ <NotFound/> }/>
                        </Routes>
                    </BrowserRouter>
                </Suspense>
            </SnackbarProvider>
        </I18nextProvider>
    </ReduxProvider>
);