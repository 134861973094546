// Dependencies
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import React, { FC, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Core & Lab
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
// Icons
import PhotoCamera from '@mui/icons-material/PhotoCamera';
// Components
// Actions
import { addFiles, deleteFile } from './redux/actions';
// Utils
import { PictureContext } from './utils/pictureContext';
// Types
import { AppState } from '../../../../Reducers/Reducers';

const PictureModalCreateTabs: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const files = useSelector((state: AppState) => state.picture.files);
    const selection = useSelector((state: AppState) => state.picture.selection);
    const onPictureChange = (event: { target: { files: any; }; }) => {
        const updated_files = [];
        for (let i = 0; i < event.target.files.length; i++) {
            updated_files.push(event.target.files[i]);
            if ((event.target.files[i].size/1024)/1024 > 10) {
                enqueueSnackbar(t('errors.file-size'), {variant: 'error'});
            }
        }
        dispatch(addFiles(updated_files));
    };
    const onDeletePicture = (index: number) => () => {
        dispatch(deleteFile(index));
    };
    return (
        <PictureContext.Consumer>
            {({singleFile}) => (
                <Grid container spacing={ 2 }>
                    {
                        files !== null && files.map((file, file_index: number) => (
                            <Grid item key={file_index} xs={ 4 }>
                                <Card>
                                    <CardMedia component={ 'img' } alt={ '' } height={ 200 } image={ URL.createObjectURL(file) }/>
                                    <CardContent>
                                        <Typography className={ 'ft-redText' }>{ (file.size/1024)/1024 > 10 ? t('errors.file-size') : '' }</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button onClick={ onDeletePicture(file_index) }>{ t('shared.delete-picture') }</Button>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))
                    }
                    <Grid item xs={ 12 }>
                        <input accept={ 'image/*' } className={ 'ft-user-picture-input' } id={ 'icon-button-file' } type={ 'file' } multiple={!singleFile} onChange={ onPictureChange }/>
                        {
                            (!singleFile || (files.length === 0 && selection.length === 0)) &&
                            <label htmlFor={ 'icon-button-file' }>
                                <Button className={ 'ft-user-picture-button' } startIcon={ <PhotoCamera className={ 'ft-user-picture-icon' }/> } component={ 'span' }>
                                    {
                                        t(
                                            singleFile ? 'shared.select-picture' : 'shared.select-picture(s)'
                                        )
                                    }
                                </Button>
                            </label>
                        }
                    </Grid>
                </Grid>
            )}
        </PictureContext.Consumer>
    );
};

export default PictureModalCreateTabs;