import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import {
    Box,
    ClickAwayListener,
    IconButton,
    styled,
    Typography
} from "@mui/material";
import { customColors } from "../utils/editor/customColors";
import { RichEditorStyleMap } from "../utils/editor/editor";
import { AppState } from "../../../../Reducers/Reducers";

type Props = {
    color: string,
    onChangeColor: (color: string) => void
}

export function MailTemplateVisualEditorColorPicker(props: Props): JSX.Element {
    const [open, setOpen] = useState(false);
    const quotationCode = useSelector((state: AppState) => state.header.quotation_code);

    const onToggle = () => {
        setOpen((state) => !state);
    };

    const editorPresets = Object.keys(RichEditorStyleMap).filter((key) => {
        return key.startsWith('COLOR_');
    }).map((key) => {
        return {
            title: key.replace('COLOR_', ''),
            color: RichEditorStyleMap[key]?.color ?? '#000'
        };
    });

    const presetStyles = quotationCode ?
        (customColors[quotationCode] ?? {}) :
        {};
    const presetColors = Object.keys(presetStyles).map((key) => {
        return {
            title: key.replace('COLOR_', ''),
            color: presetStyles[key]?.color ?? '#000'
        };
    });

    return (
        <Container>
            <HexPreview>{props.color}</HexPreview>
            <CTAContainer>
                <IconButton onClick={ onToggle }>
                    <ColorBox sx={{
                        backgroundColor: props.color,
                        border: '1px solid #a5a5a5'
                    }} />
                </IconButton>
                {
                    open &&
                    <PickerContainer>
                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                            <div onClick={(event) => event.stopPropagation()}>
                                <SketchPicker
                                    color={props.color}
                                    presetColors={editorPresets.concat(presetColors)}
                                    onChangeComplete={(color) => {
                                        const rgba = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a ?? '255'})`;
                                        props.onChangeColor(rgba);
                                    }}
                                />
                            </div>
                        </ClickAwayListener>
                    </PickerContainer>
                }
            </CTAContainer>
        </Container>
    );
}

const Container = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const HexPreview = styled(Typography)((props) => ({
    padding: props.theme.spacing(1),
    textAlign: 'center'
}));

const ColorBox = styled(Box)((props) => ({
    width: 16,
    height: 16,
    borderRadius: props.theme.shape.borderRadius,
    cursor: 'pointer'
}));

const CTAContainer = styled('div')(() => ({
    position: 'relative'
}));

const PickerContainer = styled('div')((props) => ({
    position: 'absolute',
    zIndex: props.theme.zIndex.drawer + 1
}));
