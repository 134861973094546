// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { HomeState } from '../objects/homeState';

const initialState: HomeState = {
    active_search_module: 'ACCOMMODATION'
};

export const homeSlice = createSlice({
    name: 'home',
    initialState,
    reducers: {
        updateActiveSearchModule: (state, action: PayloadAction<'FLIGHT' | 'ACCOMMODATION' | 'CAR'>) => {
            state.active_search_module = action.payload;
        }
    }
});

export const {
    updateActiveSearchModule
} = homeSlice.actions;

export default homeSlice.reducer;