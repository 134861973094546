// Dependencies
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Actions
import { setTotals } from './redux/reducer';
// Types
import { AppState } from '../../reducers';

const TripCost: FC = (): null => {
    const dispatch = useDispatch();
    const trip = useSelector((state: AppState) => state.trip.trip);
    const accommodations = useSelector((state: AppState) => state.accommodation.cart);
    const manual_products = useSelector((state: AppState) => state.cart.manual_products);

    const getPrice = (prices): number => {
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].is_tva) {
                return parseFloat(prices[i].selling_price);
            }
        }
        for (let i = 0; i < prices.length; i++) {
            if (prices[i].master_price) {
                return parseFloat(prices[i].selling_price);
            }
        }
        return 0;
    };
    useEffect(() => {
        if (trip !== null) {
            let total: number = 0;
            let total_accommodation = 0;
            let total_manual = 0;
            accommodations.map(accommodation => {
                total_accommodation += getPrice(accommodation.prices);
            });
            manual_products.map(product => {
                if (product.linked_product === null || product.product_type === 16) {
                    total_manual += getPrice(product.prices);
                }
            });
            total = total_accommodation + total_manual;
            dispatch(setTotals({total: total, total_accommodation: total_accommodation, total_manual: total_manual}));
        }
    }, [trip, accommodations, manual_products]);
    return null;
};

export default TripCost;