// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Functions
import formatErrors from '../../../functions/formatErrors';
// Types
import { Errors } from '../objects/errors';
import { ResetPasswordState } from '../objects/resetPasswordState';

const initialState: ResetPasswordState = {
    confirm_new_password: '',
    confirm_new_password_error: '',
    new_password: '',
    new_password_error: ''
};

export const resetPasswordSlice = createSlice({
    name: 'resetPassword',
    initialState,
    reducers: {
        setResetPasswordErrors: (state, action: PayloadAction<Errors>) => {
            const { password, confirm_password } = action.payload;
            state.confirm_new_password_error = confirm_password ? Array.isArray(confirm_password) ? formatErrors(confirm_password) : confirm_password : '';
            state.new_password_error = password ? Array.isArray(password) ? formatErrors(password) : password : '';
        },
        updateConfirmNewPassword: (state, action: PayloadAction<string>) => {
            state.confirm_new_password = action.payload;
        },
        updateNewPassword: (state, action: PayloadAction<string>) => {
            state.new_password = action.payload;
        },
    }
});

export const {
    setResetPasswordErrors,
    updateConfirmNewPassword,
    updateNewPassword
} = resetPasswordSlice.actions;

export default resetPasswordSlice.reducer;