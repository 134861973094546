// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { Provider } from '../objects/provider';
import { ProviderState } from '../objects/providerState';

const initialState: ProviderState = {
    providers: null
};

export const providerSlice = createSlice({
    name: 'provider',
    initialState,
    reducers: {
        setProviders: (state, action: PayloadAction<Provider[]>) => {
            state.providers = action.payload;
        }
    }
});

export const {
    setProviders
} = providerSlice.actions;

export default providerSlice.reducer;