// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { Event } from '../objects/event';
import { EventState } from '../objects/eventState';

const initialState: EventState = {
    activated_module: [],
    activation_date_id: null,
    allow_deposit: false,
    allowed_hotels: [],
    background_color: '#DD263D',
    button_color: '#DD263D',
    cancellation_limit_date: null,
    cart_amwc: false,
    deposit_limit_date: null,
    description: '',
    event_end_date: null,
    event_start_date: null,
    footer_elements: null,
    id: null,
    is_password_protected: false,
    is_silent_signin: false,
    lang_available: null,
    localization: [],
    logo: null,
    meeting_code: null,
    name: '',
    owner: 1,
    password: null,
    password_entered: '',
    pictures: [],
    place: null,
    sub_domain: '',
    text_color: '#DD263D',
    title: '',
    traveler_can_add: true,
    traveler_can_delete: false,
    typo: null,
    opened_btc: false,
    pax_update_limit_date: null,
    bank_transfer_activated: false,
    bank_transfer_limit_date: null
};

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {
        setEvent: (state, action: PayloadAction<Event>) => {
            state.activated_module = action.payload.activated_module;
            state.allow_deposit = action.payload.allow_deposit;
            state.allowed_hotels = action.payload.allowed_hotels;
            state.background_color = action.payload.background_color;
            state.button_color = action.payload.button_color;
            state.cancellation_limit_date = action.payload.cancellation_limit_date;
            state.deposit_limit_date = action.payload.deposit_limit_date;
            state.description = action.payload.description;
            state.event_end_date = action.payload.event_end_date;
            state.event_start_date = action.payload.event_start_date;
            state.footer_elements = action.payload.footer_elements;
            state.id = action.payload.id;
            state.is_silent_signin = action.payload.is_silent_signin;
            state.lang_available = action.payload.lang_available;
            state.localization = action.payload.localization;
            state.logo = action.payload.logo;
            state.meeting_code = action.payload.meeting_code;
            state.name = action.payload.name;
            state.owner = action.payload.owner;
            state.pictures = action.payload.pictures;
            state.place = action.payload.place;
            state.sub_domain = action.payload.sub_domain;
            state.text_color = action.payload.text_color;
            state.title = action.payload.title;
            state.traveler_can_add = action.payload.traveler_can_add;
            state.traveler_can_delete = action.payload.traveler_can_delete;
            state.typo = action.payload.typo;
            state.opened_btc = action.payload.opened_btc;
            state.pax_update_limit_date = action.payload.pax_update_limit_date;
            state.bank_transfer_activated = action.payload.bank_transfer_activated;
            state.bank_transfer_limit_date = action.payload.bank_transfer_limit_date;
            state.password = action.payload.event_password;
            state.password_entered = '';
            state.is_password_protected = action.payload.event_password !== null;
            state.activation_date_id = action.payload.activation_date_id;
            state.cart_amwc = action.payload.cart_amwc;
        },
        toggleIsPasswordProtected: (state) => {
            state.is_password_protected = !state.is_password_protected;
        }
    }
});


export const {
    setEvent,
    toggleIsPasswordProtected,
} = eventSlice.actions;

export default eventSlice.reducer;