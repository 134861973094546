import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { clone } from "lodash";
import { MailTemplateVisualEditorHeightOption } from "../mailTemplateVisualEditorHeightOption";
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import { generateBlockId } from "../utils/block-id";
import { registerBlock } from "../utils/registered-blocks";
import { setVisualEditorBlockOptions } from "../redux/actions";
import { Block } from "../objects/block";
import { AppState } from "../../../../../Reducers/Reducers";
import Icon from '@mui/icons-material/Expand';

type Options = {
    backgroundColor: string,
    height: number,
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}

function MailTemplateVisualEditorSpacerBlock(props: Props): JSX.Element {
    return (
        <Box
            sx={{
                backgroundColor: props.options.backgroundColor,
                height: props.options.height + 'px'
            }}
        />
    );
}

function MailTemplateVisualEditorSpacerBlockOptions(props: OptionsComponentProps): JSX.Element {
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.locale.current_locale);

    const onChangeOption = (key: keyof OptionsComponentProps["options"], value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                {
                    ...props.options,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    };

    return (
        <div>
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption('backgroundColor', color)}
            />
            <MailTemplateVisualEditorHeightOption
                height={props.options.height}
                onChangeHeight={((height) => onChangeOption('height', height))}
            />
        </div>
    );
}

export class SpacerBlock implements Block<Options> {
    private id;
    private options: Options;

    public constructor() {
        this.id = generateBlockId();
        this.options = {
            backgroundColor: '#fff',
            height: 20
        };
    }

    public getType(): string {
        return "spacer";
    }

    public getId(): number {
        return this.id;
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public renderMjml(): string {
        return `
            <mj-spacer
                container-background-color="${this.options.backgroundColor}"
                height="${this.options.height}px"
                css-class="spacer-block"
            />
        `;
    }

    public clone(options?: Options): SpacerBlock {
        let block: SpacerBlock;

        if (options) {
            block = clone(this);
        } else {
            block = new SpacerBlock();
        }

        const blockOptions = options ?? this.getOptions();
        block.setOptions(blockOptions);

        return block;
    }

    public findBlock(id: number): SpacerBlock | undefined {
        if (id === this.getId()) {
            return this;
        }
    }
}

registerBlock({
    type: "spacer",
    icon: <Icon />,
    label: "shared.mail-template-visual-editor-spacer-block-label",
    component: MailTemplateVisualEditorSpacerBlock,
    optionsComponent: MailTemplateVisualEditorSpacerBlockOptions,
    factory: () => new SpacerBlock()
});
