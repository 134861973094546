// import { configureStore, createAction, PayloadAction } from '@reduxjs/toolkit';
// import { persistStore, persistReducer } from 'reduxjs-toolkit-persist'
// import { AppState, rootReducer } from './reducers';
// import storage from './storage';
//
// const persistConfig = {
//     key: 'root',
//     storage,
// }
//
// const reducer = (state: AppState | undefined, action: PayloadAction) => {
//     if (action.type === logout.toString()) {
//         return rootReducer(undefined, action);
//     }
//
//     return rootReducer(state, action);
// };
//
// export const logout = createAction('LOGOUT');
//
// const store = configureStore({
//     reducer: persistReducer(persistConfig, reducer),
//     middleware: (getDefaultMiddleware: (arg0: { immutableCheck: boolean; serializableCheck: boolean; }) => any) => getDefaultMiddleware({
//         immutableCheck: false,
//         serializableCheck: false
//     })
// });
//
//
// export const persistor = persistStore(store);
//
// export default store

import { configureStore, createAction, PayloadAction } from '@reduxjs/toolkit';
import { AppState, rootReducer } from './reducers';

const reducer = (state: AppState | undefined, action: PayloadAction) => {
    if (action.type === logout.toString()) {
        return rootReducer(undefined, action);
    }
    return rootReducer(state, action);
};

export const logout = createAction('LOGOUT');

export default configureStore({
    reducer,
    middleware: (getDefaultMiddleware: (arg0: { immutableCheck: boolean; serializableCheck: boolean; }) => any) => getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false
    })
});