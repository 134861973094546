import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
import { FolderState } from '../objects/folderState';
import { Folder } from '../objects/folderType';


const initialState:FolderState = {
    folders: [],
    folder: null
};

export const folderSlice = createSlice({
    name: 'folder',
    initialState,
    reducers: {
        setFolder: (
            state,
            action: PayloadAction<{folderState: Folder[]}>
        ): void => {
            state.folders=[];
            action.payload.folderState.forEach((folder)=>
                state.folders.push(folder));
        },
        changeFolder: (
            state,
            action: PayloadAction<{folderState: Folder}>
        ): void => {
            state.folder = action.payload.folderState;
        },
    }
});


export const {
    setFolder, changeFolder
} = folderSlice.actions;

export default folderSlice;