// Dependencies
import { createSlice } from '@reduxjs/toolkit';
// Types
import { FooterState } from '../objects/footerState';

const initialState: FooterState = {
    about_us_open: false,
    contact_us_open: false,
    security_open: false,
    terms_and_conditions_open: false
};

export const footerSlice = createSlice({
    name: 'footer',
    initialState,
    reducers: {
        toggleAboutUs: (state) => {
            state.about_us_open = !state.about_us_open;
        },
        toggleContactUs: (state) => {
            state.contact_us_open = !state.contact_us_open;
        },
        toggleSecurity: (state) => {
            state.security_open = !state.security_open;
        },
        toggleTermsAndConditions: (state) => {
            state.terms_and_conditions_open = !state.terms_and_conditions_open;
        }
    }
});


export const {
    toggleAboutUs,
    toggleContactUs,
    toggleSecurity,
    toggleTermsAndConditions
} = footerSlice.actions;

export default footerSlice.reducer;