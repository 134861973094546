import React, { useEffect, useState } from "react";
import { Alert, Button, Snackbar, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import setCookie from "../functions/setCookie";
import getCookie from "../functions/getCookie";
import { useSelector } from "react-redux";
import { AppState } from "../reducers";

const AcceptCookie = () => {
    const { t } = useTranslation();
    const sub_domain = useSelector((state: AppState) => state.event.sub_domain);

    const [open, setOpen] = useState(getCookie(`accept-cookie-${sub_domain}`) === null);

    const acceptCookies = () => {
        setCookie(`accept-cookie-${sub_domain}`, true, 365, `/`, (window.location.host === "localhost:3000" ? null : window.location.host));
        setOpen(false);
    };
    return (
        <Snackbar open={open} sx={{ right: '0px !important', left: '0px !important', bottom: '0px !important' }}>
            <Alert
                severity="warning"
                variant="filled"
                sx={{
                    width: '100%',
                    '& .MuiAlert-message': {
                        width: '100%'
                    }
                }}
            >
                <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                    <Stack direction={'column'}>
                        <Typography>{t('accept-cookie-title')}</Typography>
                        <Typography>
                            {t('accept-cookie')}
                        </Typography>
                    </Stack>
                    <Button
                        variant="outlined"
                        sx={{
                            color: 'white',
                            borderColor: 'white',
                            '&:hover': {
                                borderColor: 'white'
                            }
                        }}
                        onClick={acceptCookies}
                    >
                        {t('understood-cookie')}
                    </Button>
                </Stack>
            </Alert>
        </Snackbar>
    );
};
export default AcceptCookie;
