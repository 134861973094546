import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, styled } from "@mui/material";
import { clone } from "lodash";
import { MailTemplateVisualEditorColorOption } from "../mailTemplateVisualEditorColorOption";
import { MailTemplateVisualEditorPaddingOptions } from "../mailTemplateVisualEditorPaddingOptions";
import { MailTemplateVisualEditorWidthOption } from "../mailTemplateVisualEditorWidthOption";
import { MailTemplateVisualEditorThicknessOption } from "../mailTemplateVisualEditorThicknessOption";
import { MailTemplateVisualEditorBackgroundColorOption } from "../mailTemplateVisualEditorBackgroundColorOption";
import {
    MailTemplateVisualEditorBorderStyle,
    MailTemplateVisualEditorBorderStyleOption
} from "../mailTemplateVisualEditorBorderStyleOption";
import { generateBlockId } from "../utils/block-id";
import { registerBlock } from "../utils/registered-blocks";
import { setVisualEditorBlockOptions } from "../redux/actions";
import { Block } from "../objects/block";
import { AppState } from "../../../../../Reducers/Reducers";
import Icon from '@mui/icons-material/HorizontalRule';

type Options = {
    backgroundColor: string,
    color: string,
    width: number,
    thickness: number,
    style: MailTemplateVisualEditorBorderStyle,
    padding: MailTemplateVisualEditorPaddingOptions
}

type Props = {
    id: number,
    options: Options
}

type OptionsComponentProps = {
    id: number,
    options: Options
}

function MailTemplateVisualEditorDividerBlock(props: Props): JSX.Element {
    return (
        <Container
            sx={{
                paddingTop: props.options.padding.top + 'px',
                paddingBottom: props.options.padding.bottom + 'px',
                paddingLeft: props.options.padding.left + 'px',
                paddingRight: props.options.padding.right + 'px',
                backgroundColor: props.options.backgroundColor
            }}
        >
            <Line
                sx={{
                    width: props.options.width + '%',
                    borderTopWidth: props.options.thickness + 'px',
                    borderTopStyle: props.options.style,
                    borderTopColor: props.options.color
                }}
            />
        </Container>
    );
}

const Container = styled(Box)(() => ({
    padding: '5px 0'
}));

const Line = styled(Box)(() => ({
    height: 0,
    margin: 'auto'
}));

function MailTemplateVisualEditorDividerBlockOptions(props: OptionsComponentProps): JSX.Element {
    const dispatch = useDispatch();
    const locale = useSelector((state: AppState) => state.locale.current_locale);

    const onChangeOption = (key: keyof OptionsComponentProps["options"], value: any) => {
        if (locale !== null) {
            const dispatchData = setVisualEditorBlockOptions(
                locale,
                props.id,
                {
                    ...props.options,
                    [key]: value
                }
            );
            dispatch(dispatchData);
        }
    };

    const onChangePadding = (type: keyof Options["padding"], value: number) => {
        onChangeOption("padding", { ...props.options.padding, [type]: value });
    };

    return (
        <div>
            <MailTemplateVisualEditorBackgroundColorOption
                backgroundColor={props.options.backgroundColor}
                onChangeBackgroundColor={(color) => onChangeOption('backgroundColor', color)}
            />
            <MailTemplateVisualEditorColorOption
                color={props.options.color}
                onChangeColor={(color) => onChangeOption('color', color)}
            />
            <MailTemplateVisualEditorWidthOption
                width={props.options.width}
                onChangeWidth={(width) => onChangeOption('width', width)}
            />
            <MailTemplateVisualEditorBorderStyleOption
                style={props.options.style}
                onChangeStyle={(style) => onChangeOption('style', style)}
            />
            <MailTemplateVisualEditorThicknessOption
                thickness={props.options.thickness}
                onChangeThickness={(thickness) => onChangeOption('thickness', thickness)}
            />
            <MailTemplateVisualEditorPaddingOptions
                padding={props.options.padding}
                onChangePadding={onChangePadding}
            />
        </div>
    );
}

export class DividerBlock implements Block<Options> {
    private id;
    private options: Options;

    public constructor() {
        this.id = generateBlockId();
        this.options = {
            backgroundColor: '#fff',
            color: '#000',
            width: 75,
            thickness: 1,
            style: 'solid',
            padding: {
                top: 5,
                bottom: 5,
                left: 0,
                right: 0
            }
        };
    }

    public getType(): string {
        return "divider";
    }

    public getId(): number {
        return this.id;
    }

    public setOptions(options: Options): void {
        this.options = options;
    }

    public getOptions(): Options {
        return this.options;
    }

    public renderMjml(): string {
        return `
            <mj-divider
                border-color="${this.options.color}"
                border-width="${this.options.thickness}px"
                border-style="${this.options.style}"
                width="${this.options.width}%"
                padding-top="${this.options.padding.top}px"
                padding-bottom="${this.options.padding.bottom}px"
                padding-left="${this.options.padding.left}px"
                padding-right="${this.options.padding.right}px"
                container-background-color="${this.options.backgroundColor}"
                css-class="divider-block"
            />
        `;
    }

    public clone(options?: Options): DividerBlock {
        let block: DividerBlock;

        if (options) {
            block = clone(this);
        } else {
            block = new DividerBlock();
        }

        const blockOptions = options ?? this.getOptions();
        block.setOptions(blockOptions);

        return block;
    }

    public findBlock(id: number): DividerBlock | undefined {
        if (id === this.getId()) {
            return this;
        }
    }
}

registerBlock({
    type: "divider",
    icon: <Icon />,
    label: "shared.mail-template-visual-editor-divider-block-label",
    component: MailTemplateVisualEditorDividerBlock,
    optionsComponent: MailTemplateVisualEditorDividerBlockOptions,
    htmlAttributes: [],
    styles: [
        `
            .divider-block p {
                min-height: 0;
                line-height: 0;
            }
        `
    ],
    factory: () => new DividerBlock()
});
