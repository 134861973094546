// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Functions
import formatErrors from '../../../functions/formatErrors';
// Types
import { Errors } from '../objects/errors';
import { LoginState } from '../objects/loginState';
import { CreateError } from '../objects/createError';

const initialState: LoginState = {
    confirm_email: '',
    confirm_email_error: '',
    confirm_password: '',
    confirm_password_error: '',
    company: '',
    company_error: '',
    create_account: false,
    email: '',
    email_error: '',
    first_name: '',
    first_name_error: '',
    forgot_password: false,
    forgot_password_email_sent: false,
    last_name: '',
    last_name_error: '',
    open: false,
    password: '',
    password_error: '',
    show_password: false,
    reset_password: false
};

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        setCreateErrors: (state, action: PayloadAction<CreateError[]>) => {
            action.payload.map(error => {
                state[error.key] = error.error;
            });
        },
        setLoginErrors: (state, action: PayloadAction<Errors>) => {
            const { email, password } = action.payload;
            state.email_error = email ? Array.isArray(email) ? formatErrors(email) : email : '';
            state.password_error = password ? Array.isArray(password) ? formatErrors(password) : password : '';
        },
        toggleCreateAccount: (state) => {
            state.create_account = !state.create_account;
            state.confirm_email = '';
            state.confirm_email_error = '';
            state.confirm_password = '';
            state.confirm_password_error = '';
            state.company = '';
            state.company_error = '';
            state.email = '';
            state.email_error = '';
            state.first_name = '';
            state.first_name_error = '';
            state.last_name = '';
            state.last_name_error = '';
            state.password = '';
            state.password_error = '';
        },
        toggleForgotPassword: (state) => {
            state.forgot_password = !state.forgot_password;
            state.confirm_email = '';
            state.confirm_email_error = '';
            state.confirm_password = '';
            state.confirm_password_error = '';
            state.email = '';
            state.email_error = '';
            state.forgot_password_email_sent = false;
            state.password = '';
            state.password_error = '';
        },
        toggleForgotPasswordEmailSent: (state) => {
            state.forgot_password_email_sent = true;
        },
        toggleResetPassword: (state, action: PayloadAction<boolean | undefined>) => {
            state.reset_password = action.payload !== undefined ? action.payload : !state.reset_password;
            state.confirm_email = '';
            state.confirm_email_error = '';
            state.confirm_password = '';
            state.confirm_password_error = '';
            state.email = '';
            state.email_error = '';
            state.password = '';
            state.password_error = '';
        },
        toggleLogin: (state, action: PayloadAction<boolean | undefined>) => {
            state.open = action.payload !== undefined ? action.payload : !state.open;
            state.confirm_email = '';
            state.confirm_email_error = '';
            state.confirm_password = '';
            state.confirm_password_error = '';
            state.email = '';
            state.email_error = '';
            state.password = '';
            state.password_error = '';
        },
        toggleShowPassword: (state) => {
            state.show_password = !state.show_password;
        },
        updateCompany: (state, action: PayloadAction<string>) => {
            state.company = action.payload;
            state.company_error = '';
        },
        updateConfirmEmail: (state, action: PayloadAction<string>) => {
            state.confirm_email = action.payload;
            state.confirm_email_error = '';
        },
        updateConfirmPassword: (state, action: PayloadAction<string>) => {
            state.confirm_password = action.payload;
            state.confirm_password_error = '';
        },
        updateEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
            state.email_error = '';
        },
        updateFirstName: (state, action: PayloadAction<string>) => {
            state.first_name = action.payload;
            state.first_name_error = '';
        },
        updateLastName: (state, action: PayloadAction<string>) => {
            state.last_name = action.payload;
            state.last_name_error = '';
        },
        updatePassword: (state, action: PayloadAction<string>) => {
            state.password = action.payload;
            state.password_error = '';
        }
    }
});

export const {
    setCreateErrors,
    setLoginErrors,
    toggleCreateAccount,
    toggleForgotPassword,
    toggleForgotPasswordEmailSent,
    toggleResetPassword,
    toggleLogin,
    toggleShowPassword,
    updateCompany,
    updateConfirmEmail,
    updateConfirmPassword,
    updateEmail,
    updateFirstName,
    updateLastName,
    updatePassword
} = loginSlice.actions;

export default loginSlice.reducer;