// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { FinishedState } from '../objects/finishedState';

const initialState: FinishedState = {
    finished: false
};

export const finishedSlice = createSlice({
    name: 'finished',
    initialState,
    reducers: {
        setFinished: (state, action: PayloadAction<boolean>) => {
            state.finished = action.payload;
        }
    }
});

export const {
    setFinished
} = finishedSlice.actions;

export default finishedSlice.reducer;