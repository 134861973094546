import { Preferences } from '../../profile/objects/preferences';
import { Query } from '../../utils/network/query';
import { GoogleFont } from '../objects/googleFont';
import { MailTemplate } from '../objects/mailTemplate';
import { MailTemplateListQueryState } from '../objects/mailTemplateListQueryState';
import { MailTemplateListServerResponse } from '../objects/mailTemplateListServerResponse';
import { MailTemplateState } from '../objects/mailTemplateState';
import { MailTemplateVisualEditorBodyStyles } from '../objects/mailTemplateVisualEditorState';
import { Mode } from "../objects/mode";

export const ADD_PREFERENCES = 'MAIL_TEMPLATE_ADD_PREFERENCES';
export const ADD_VISUAL_EDITOR_BLOCK = 'MAIL_TEMPLATE_ADD_VISUAL_EDITOR_BLOCK';
export const CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT = 'MAIL_TEMPLATE_CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT';
export const CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT = 'MAIL_TEMPLATE_CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT';
export const DELETE_VISUAL_EDITOR_BLOCK = 'MAIL_TEMPLATE_DELETE_VISUAL_EDITOR_BLOCK';
export const DUPLICATE_VISUAL_EDITOR_BLOCK = 'MAIL_TEMPLATE_DUPLICATE_VISUAL_EDITOR_BLOCK';
export const MOVE_DOWN_COLUMN = 'DMAIL_TEMPLATEMOVE_DOWN_COLUMN';
export const MOVE_UP_COLUMN = 'MAIL_TEMPLATE_MOVE_UP_COLUMN';
export const REDO_VISUAL_EDITOR = 'MAIL_TEMPLATE_REDO_VISUAL_EDITOR';
export const REORDER_VISUAL_EDITOR_BLOCKS = 'MAIL_TEMPLATE_REORDER_VISUAL_EDITOR_BLOCKS';
export const REPLACE_ITEM_IN_LIST = 'MAIL_TEMPLATE_REPLACE_ITEM_IN_LIST';
export const RESET_CREATE_FORM = 'MAIL_TEMPLATE_RESET_CREATE_FORM';
export const RESET_EDITORS = 'MAIL_TEMPLATE_RESET_EDITORS';
export const RESET_FILTERS = 'MAIL_TEMPLATE_RESET_FILTERS';
export const RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML = 'MAIL_TEMPLATE_RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML';
export const SELECT_VISUAL_EDITOR_BLOCK = 'MAIL_TEMPLATE_SELECT_VISUAL_EDITOR_BLOCK';
export const SET_AUTOCOMPLETE = 'MAIL_TEMPLATE_SET_AUTOCOMPLETE';
export const SET_CREATE_ACTION = 'MAIL_TEMPLATE_SET_CREATE_ACTION';
export const SET_CREATE_EXTRA_PARAMS = 'MAIL_TEMPLATE_SET_CREATE_EXTRA_PARAMS';
export const SET_CREATE_NAME = 'MAIL_TEMPLATE_SET_CREATE_NAME';
export const SET_CREATE_SUBJECT = 'MAIL_TEMPLATE_SET_CREATE_SUBJECT';
export const SET_EDITOR_CHOICE = 'MAIL_TEMPLATE_SET_EDITOR_CHOICE';
export const SET_EXPORT_TYPE = 'MAIL_TEMPLATE_SET_EXPORT_TYPE';
export const SET_GOOGLE_FONTS = 'MAIL_TEMPLATE_SET_GOOGLE_FONTS';
export const SET_HTML_CODE_EDITOR_CONTENT = 'MAIL_TEMPLATE_SET_HTML_CODE_EDITOR_CONTENT';
export const SET_LIMIT = 'MAIL_TEMPLATE_SET_LIMIT';
export const SET_LIST = 'MAIL_TEMPLATE_SET_LIST';
export const SET_LOADING = 'MAIL_TEMPLATE_SET_LOADING';
export const SET_MAIL_TEMPLATES = 'MAIL_TEMPLATE_SET_MAIL_TEMPLATES';
export const SET_ORDER_BY = 'MAIL_TEMPLATE_SET_ORDER_BY';
export const SET_PAGE = 'MAIL_TEMPLATE_SET_PAGE';
export const SET_PREFERENCES = 'MAIL_TEMPLATE_SET_PREFERENCES';
export const SET_QUERY = 'MAIL_TEMPLATE_SET_QUERY';
export const SET_RECIPIENTS = 'MAIL_TEMPLATE_SET_RECIPIENTS';
export const SET_VISUAL_EDITOR_BLOCK_OPTIONS = 'MAIL_TEMPLATE_SET_VISUAL_EDITOR_BLOCK_OPTIONS';
export const SET_VISUAL_EDITOR_BODY_STYLES = 'MAIL_TEMPLATE_SET_VISUAL_EDITOR_BODY_STYLES';
export const SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID = 'MAIL_TEMPLATE_SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID';
export const SET_VISUAL_EDITOR_MODE = 'MAIL_TEMPLATE_SET_VISUAL_EDITOR_MODE';
export const TOGGLE_CANCEL_MODAL = 'MAIL_TEMPLATE_TOGGLE_CANCEL_MODAL';
export const TOGGLE_COLUMN = 'MAIL_TEMPLATE_TOGGLE_COLUMN';
export const TOGGLE_CREATE = 'MAIL_TEMPLATE_TOGGLE_CREATE';
export const TOGGLE_EXPORT = 'MAIL_TEMPLATE_TOGGLE_EXPORT';
export const TOGGLE_EXPORT_FULL = 'MAIL_TEMPLATE_TOGGLE_EXPORT_FULL';
export const TOGGLE_FILTERS = 'MAIL_TEMPLATE_TOGGLE_FILTERS';
export const TOGGLE_REFRESH = 'MAIL_TEMPLATE_TOGGLE_REFRESH';
export const UNDO_VISUAL_EDITOR = 'MAIL_TEMPLATE_UNDO_VISUAL_EDITOR';
export const UNSELECT_VISUAL_EDITOR_BLOCK = 'MAIL_TEMPLATE_UNSELECT_VISUAL_EDITOR_BLOCK';
export const UPDATE_FILTER_ACTION = 'MAIL_TEMPLATE_UPDATE_FILTER_ACTION';
export const UPDATE_FILTER_CREATED_DATE_GTE = 'MAIL_TEMPLATE_UPDATE_FILTER_CREATED_DATE_GTE';
export const UPDATE_FILTER_CREATED_DATE_LTE = 'MAIL_TEMPLATE_UPDATE_FILTER_CREATED_DATE_LTE';
export const UPDATE_FILTER_CREATOR = 'MAIL_TEMPLATE_UPDATE_FILTER_CREATOR';
export const UPDATE_FILTER_LAST_AUTHOR = 'MAIL_TEMPLATE_UPDATE_FILTER_LAST_AUTHOR';
export const UPDATE_FILTER_MODIFIED_DATE_GTE = 'MAIL_TEMPLATE_UPDATE_FILTER_MODIFIED_DATE_GTE';
export const UPDATE_FILTER_MODIFIED_DATE_LTE = 'MAIL_TEMPLATE_UPDATE_FILTER_MODIFIED_DATE_LTE';
export const UPDATE_FILTER_NAME = 'MAIL_TEMPLATE_UPDATE_FILTER_NAME';
export const UPDATE_FILTER_PERIOD = 'MAIL_TEMPLATE_UPDATE_FILTER_PERIOD';
export const UPDATE_FILTER_PERIOD_TYPE = 'MAIL_TEMPLATE_UPDATE_FILTER_PERIOD_TYPE';
export const UPDATE_MAIL_TEMPLATE_LIST_QUERY = "MAIL_TEMPLATE_UPDATE_MAIL_TEMPLATE_LIST_QUERY";

interface AddPreferencesAction {
    type: typeof ADD_PREFERENCES,
    payload: Preferences[]
}

interface AddVisualEditorBlockAction {
    type: typeof ADD_VISUAL_EDITOR_BLOCK,
    payload: {
        locale: number,
        type: string | symbol,
        prevBlockId: number | null
    }
}

interface CreateHtmlCodeEditorLocaleInitialContentAction {
    type: typeof CREATE_HTML_CODE_EDITOR_LOCALE_INITIAL_CONTENT,
    payload: {
        locale: number
    }
}

interface CreateVisualEditorLocaleInitialContentAction {
    type: typeof CREATE_VISUAL_EDITOR_LOCALE_INITIAL_CONTENT,
    payload: {
        locale: number
    }
}

interface DeleteVisualEditorBlockAction {
    type: typeof DELETE_VISUAL_EDITOR_BLOCK,
    payload: {
        locale: number,
        id: number
    }
}

interface DuplicateVisualEditorBlock {
    type: typeof DUPLICATE_VISUAL_EDITOR_BLOCK,
    payload: {
        locale: number,
        id: number
    }
}

interface MoveDownColumnAction {
    type: typeof MOVE_DOWN_COLUMN,
    payload: number
}

interface MoveUpColumnAction {
    type: typeof MOVE_UP_COLUMN,
    payload: number
}

interface RedoVisualEditorAction {
    type: typeof REDO_VISUAL_EDITOR
}

interface ReorderVisualEditorBlocksAction {
    type: typeof REORDER_VISUAL_EDITOR_BLOCKS,
    payload: {
        locale: number,
        id: number,
        index: number
    }
}

interface ReplaceItemInListAction {
    type: typeof REPLACE_ITEM_IN_LIST,
    payload: MailTemplate
}

interface ResetCreateFormAction {
    type: typeof RESET_CREATE_FORM
}

interface ResetEditorsAction {
    type: typeof RESET_EDITORS
}

interface ResetFiltersAction {
    type: typeof RESET_FILTERS
}

interface RestoreVisualEditorBlocksFromHtmlAction {
    type: typeof RESTORE_VISUAL_EDITOR_BLOCKS_FROM_HTML,
    payload: {
        quotationCode: string | null,
        locale: number,
        html: string
    }
}

interface SelectVisualEditorBlockAction {
    type: typeof SELECT_VISUAL_EDITOR_BLOCK,
    payload: number
}

interface SetAutocompleteAction {
    type: typeof SET_AUTOCOMPLETE,
    payload: MailTemplateState['autocomplete']
}

interface SetCreateActionAction {
    type: typeof SET_CREATE_ACTION,
    payload: string
}

interface SetCreateExtraParamsAction {
    type: typeof SET_CREATE_EXTRA_PARAMS,
    payload: MailTemplateState["create_extra_params"]
}

interface SetCreateNameAction {
    type: typeof SET_CREATE_NAME,
    payload: {
        locale: number,
        value: string
    }
}

interface SetCreateSubjectAction {
    type: typeof SET_CREATE_SUBJECT,
    payload: {
        locale: number,
        value: string
    }
}

interface SetEditorChoiceAction {
    type: typeof SET_EDITOR_CHOICE,
    payload: number
}

interface SetExportTypeAction {
    type: typeof SET_EXPORT_TYPE,
    payload: 'CSV' | 'PDF'
}

interface SetGoogleFontsAction {
    type: typeof SET_GOOGLE_FONTS,
    payload: GoogleFont[]
}

interface SetHtmlCodeEditorContentAction {
    type: typeof SET_HTML_CODE_EDITOR_CONTENT,
    payload: {
        locale: number,
        content: string
    }
}

interface SetLimitAction {
    type: typeof SET_LIMIT,
    payload: number
}

interface SetLoadingAction {
    type: typeof SET_LOADING,
    payload: boolean
}

interface SetMailTemplatesAction {
    type: typeof SET_MAIL_TEMPLATES,
    payload: MailTemplate[] | null
}

interface SetOrderByAction {
    type: typeof SET_ORDER_BY,
    payload: string
}

interface SetPageAction {
    type: typeof SET_PAGE,
    payload: number
}

interface SetPreferencesAction {
    type: typeof SET_PREFERENCES,
    payload: Preferences[]
}

interface SetQueryAction {
    type: typeof SET_QUERY,
    payload: {
        key: string,
        query: Query<any>
    }
}

interface SetRecipientsAction {
    type: typeof SET_RECIPIENTS,
    payload: MailTemplateState['recipients']
}

interface SetVisualEditorBlockOptionsAction {
    type: typeof SET_VISUAL_EDITOR_BLOCK_OPTIONS,
    payload: {
        locale: number,
        id: number,
        options: any
    }
}

interface SetVisualEditorBodyStylesAction {
    type: typeof SET_VISUAL_EDITOR_BODY_STYLES,
    payload: MailTemplateVisualEditorBodyStyles
}

interface SetVisualEditorDraggedBlockIdAction {
    type: typeof SET_VISUAL_EDITOR_DRAGGED_BLOCK_ID,
    payload: number | null
}

interface SetVisualEditorModeAction {
    type: typeof SET_VISUAL_EDITOR_MODE,
    payload: Mode
}

interface ToggleCancelModalAction {
    type: typeof TOGGLE_CANCEL_MODAL
}

interface ToggleColumnAction {
    type: typeof TOGGLE_COLUMN,
    payload: number
}

interface ToggleCreateAction {
    type: typeof TOGGLE_CREATE
}

interface ToggleExportAction {
    type: typeof TOGGLE_EXPORT,
    payload: boolean
}

interface ToggleExportFullAction {
    type: typeof TOGGLE_EXPORT_FULL
}

interface ToggleFiltersAction {
    type: typeof TOGGLE_FILTERS
}

interface ToggleRefreshAction {
    type: typeof TOGGLE_REFRESH
}

interface UndoVisualEditorAction {
    type: typeof UNDO_VISUAL_EDITOR
}

interface UnselectVisualEditorBlockAction {
    type: typeof UNSELECT_VISUAL_EDITOR_BLOCK
}

interface UpdateFilterActionFieldAction {
    type: typeof UPDATE_FILTER_ACTION,
    payload: string | ''
}

interface UpdateFilterCreatedDateGteAction {
    type: typeof UPDATE_FILTER_CREATED_DATE_GTE,
    payload: string | null
}

interface UpdateFilterCreatedDateLteAction {
    type: typeof UPDATE_FILTER_CREATED_DATE_LTE,
    payload: string | null
}

interface UpdateFilterCreatorAction {
    type: typeof UPDATE_FILTER_CREATOR,
    payload: string
}

interface UpdateFilterLastAuthorAction {
    type: typeof UPDATE_FILTER_LAST_AUTHOR,
    payload: string
}

interface UpdateFilterModifiedDateGteAction {
    type: typeof UPDATE_FILTER_MODIFIED_DATE_GTE,
    payload: string | null
}

interface UpdateFilterModifiedDateLteAction {
    type: typeof UPDATE_FILTER_MODIFIED_DATE_LTE,
    payload: string | null
}

interface UpdateFilterNameAction {
    type: typeof UPDATE_FILTER_NAME,
    payload: string
}

interface UpdateFilterPeriodAction {
    type: typeof UPDATE_FILTER_PERIOD,
    payload: 'CREATION' | 'MODIFICATION'
}

interface UpdateFilterPeriodTypeAction {
    type: typeof UPDATE_FILTER_PERIOD_TYPE,
    payload: number
}

interface UpdateMailTemplateListQueryAction {
    type: typeof UPDATE_MAIL_TEMPLATE_LIST_QUERY,
    payload: {
        state: MailTemplateListQueryState,
        data: MailTemplateListServerResponse
    }
}

export type MailTemplateActionTypes =
    AddPreferencesAction |
    AddVisualEditorBlockAction |
    CreateHtmlCodeEditorLocaleInitialContentAction |
    CreateVisualEditorLocaleInitialContentAction |
    DeleteVisualEditorBlockAction |
    DuplicateVisualEditorBlock |
    MoveDownColumnAction |
    MoveUpColumnAction |
    RedoVisualEditorAction |
    ReorderVisualEditorBlocksAction |
    ReplaceItemInListAction |
    ResetCreateFormAction |
    ResetEditorsAction |
    ResetFiltersAction |
    RestoreVisualEditorBlocksFromHtmlAction |
    SelectVisualEditorBlockAction |
    SetAutocompleteAction |
    SetCreateActionAction |
    SetCreateExtraParamsAction |
    SetCreateNameAction |
    SetCreateSubjectAction |
    SetEditorChoiceAction |
    SetExportTypeAction |
    SetGoogleFontsAction |
    SetHtmlCodeEditorContentAction |
    SetLimitAction |
    SetLoadingAction |
    SetMailTemplatesAction |
    SetOrderByAction |
    SetPageAction |
    SetPreferencesAction |
    SetQueryAction |
    SetRecipientsAction |
    SetVisualEditorBlockOptionsAction |
    SetVisualEditorBodyStylesAction |
    SetVisualEditorDraggedBlockIdAction |
    SetVisualEditorModeAction |
    ToggleCancelModalAction |
    ToggleColumnAction |
    ToggleCreateAction |
    ToggleExportAction |
    ToggleExportFullAction |
    ToggleFiltersAction |
    ToggleRefreshAction |
    UndoVisualEditorAction |
    UnselectVisualEditorBlockAction |
    UpdateFilterActionFieldAction |
    UpdateFilterCreatedDateGteAction |
    UpdateFilterCreatedDateLteAction |
    UpdateFilterCreatorAction |
    UpdateFilterLastAuthorAction |
    UpdateFilterModifiedDateGteAction |
    UpdateFilterModifiedDateLteAction |
    UpdateFilterNameAction |
    UpdateFilterPeriodAction |
    UpdateFilterPeriodTypeAction |
    UpdateMailTemplateListQueryAction;
