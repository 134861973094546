declare let API_HREF: string;
//Dependencies
import axios from 'axios';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import React, { FC, ReactElement, useContext, useEffect } from 'react';
//Core & Lab
import Button from '@mui/material/Button';
//Actions
import { addSelection, deleteFile, setAdding, addCustomPictures, updateProgress } from './redux/actions';
//Utils
import { PictureContext } from './utils/pictureContext';
//Types
import { AppState } from '../../../../Reducers/Reducers';

const PictureModalCreate: FC = (): ReactElement => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const client_id = useSelector((state: AppState) => state.header.client_id);
    const adding = useSelector((state: AppState) => state.picture.adding);
    const create_alt = useSelector((state: AppState) => state.picture.create_alt);
    const create_credit = useSelector((state: AppState) => state.picture.create_credit);
    const create_description = useSelector((state: AppState) => state.picture.create_description);
    const create_name = useSelector((state: AppState) => state.picture.create_name);
    const files = useSelector((state: AppState) => state.picture.files);
    const selection = useSelector((state: AppState) => state.picture.selection);
    const pictureContext = useContext(PictureContext);

    const onCreate = () => {
        dispatch(setAdding(true));
        files.map((file, file_index) => {
            const request = new FormData();
            if (create_name) {
                request.append('name', create_name);
            } else {
                request.append('name', t<string>('shared.picture'));
            }

            if (create_alt) {
                request.append('alt', create_alt);
            }

            if (create_credit) {
                request.append('credits', create_credit);
            }

            if (create_description) {
                request.append('description', create_description);
            }

            request.append('image', file);
            axios({
                method: 'POST',
                url: `${API_HREF}client/${client_id}/pictures/`,
                headers: {
                    Authorization: `Token ${localStorage.getItem('token')}`
                },
                params: {
                    type: 'PICTURE'
                },
                data: request,
                onUploadProgress: (progressEvent) => {
                    dispatch(updateProgress(progressEvent.loaded / progressEvent.total * 100));
                }
            }).then((response) => {
                dispatch(addSelection(response.data, file.name));
            }).catch((error) => {
                dispatch(deleteFile(file_index));
                if (error.response) {
                    if (error.response.status === 400) {
                        for (const [key, value] of Object.entries(error.response.data)) {
                            if (Array.isArray(value)) {
                                value.map(error => enqueueSnackbar(`${key} : ${error}`, { variant: 'error' }));
                            } else {
                                enqueueSnackbar(`${key} : ${value}`, { variant: 'error' });
                            }
                        }
                    } else if (error.response.status === 401) {
                        enqueueSnackbar(t<string>('errors.permissions'), { variant: 'error' });
                    } else if (error.response.status === 500) {
                        enqueueSnackbar(t<string>('errors.servers'), { variant: 'error' });
                    }
                } else if (error.request) {
                    console.log('error.request : ', error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log('error.config : ', error.config);
            });
        });
    };
    useEffect(() => {
        if (adding && files.length === 0) {
            //if (manual_accommodation !== null) {
            dispatch(addCustomPictures(selection));
            if (pictureContext.onChangePictures) {
                pictureContext.onChangePictures(selection);
            }
            //}
        }
    }, [adding, files]);
    return (
        <PictureContext.Consumer>
            {({ disabled }) => (
                <Button
                    onClick={ onCreate }
                    disabled={
                        disabled ||
                        files.length === 0 && selection.length === 0
                    }
                >
                    { t<string>('shared.validate-selection') }
                </Button>
            )}
        </PictureContext.Consumer>
    );
};

export default PictureModalCreate;
