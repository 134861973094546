import { List } from '../objects/list';
import { Picture } from '../objects/picture';

export const ADD_CUSTOM_PICTURES = 'PICTURE_ADD_CUSTOM_PICTURES';
export const ADD_FILES = 'PICTURE_ADD_FILES';
export const ADD_SELECTION = 'PICTURE_ADD_SELECTION';
export const DELETE_FILE = 'PICTURE_DELETE_FILE';
export const DELETE_SELECTION = 'PICTURE_DELETE_SELECTION';
export const SET_ADDING = 'PICTURE_SET_ADDING';
export const SET_CROPPER = 'PICTURE_SET_CROPPER';
export const SET_LIMIT = 'PICTURE_SET_LIMIT';
export const SET_LIST = 'PICTURE_SET_LIST';
export const SET_PAGE = 'PICTURE_SET_PAGE';
export const SET_PICTURE = 'PICTURE_SET_PICTURE';
export const SET_RUNNING = 'PICTURE_SET_RUNNING';
export const SET_SEARCH = 'PICTURE_SET_SEARCH';
export const TOGGLE_CREATE = 'PICTURE_TOGGLE_CREATE';
export const TOGGLE_KEEP = 'PICTURE_TOGGLE_KEEP';
export const UPDATE_ALT = 'PICTURE_UPDATE_ALT';
export const UPDATE_CREATE_TAB = 'PICTURE_UPDATE_CREATE_TAB';
export const UPDATE_CREDITS = 'PICTURE_UPDATE_CREDITS';
export const UPDATE_DESCRIPTION = 'PICTURE_UPDATE_DESCRIPTION';
export const UPDATE_NAME = 'PICTURE_UPDATE_NAME';
export const UPDATE_PROGRESS = 'PICTURE_UPDATE_PROGRESS';

interface AddCustomPicturesAction {
    type: typeof ADD_CUSTOM_PICTURES,
    payload: Picture[]
}

interface AddFilesAction {
    type: typeof ADD_FILES,
    payload: any[]
}

interface AddSelectionAction {
    type: typeof ADD_SELECTION,
    payload: {
        picture: Picture,
        name: null | string
    }
}

interface DeleteFileAction {
    type: typeof DELETE_FILE,
    payload: number
}

interface DeleteSelectionAction {
    type: typeof DELETE_SELECTION,
    payload: number
}

interface SetAddingAction {
    type: typeof SET_ADDING,
    payload: boolean
}

interface SetCropperAction {
    type: typeof SET_CROPPER,
    payload: any
}

interface SetLimitAction {
    type: typeof SET_LIMIT,
    payload: number
}

interface SetListAction {
    type: typeof SET_LIST,
    payload: List
}

interface SetPageAction {
    type: typeof SET_PAGE,
    payload: number
}

interface SetPictureAction {
    type: typeof SET_PICTURE,
    payload: boolean | Picture
}

interface SetRunningAction {
    type: typeof SET_RUNNING,
    payload: boolean
}

interface SetSearchAction {
    type: typeof SET_SEARCH,
    payload: string
}

interface ToggleCreateAction {
    type: typeof TOGGLE_CREATE
}

interface ToggleKeepAction {
    type: typeof TOGGLE_KEEP
}

interface UpdateAltAction {
    type: typeof UPDATE_ALT,
    payload: string
}

interface UpdateCreateTabAction {
    type: typeof UPDATE_CREATE_TAB,
    payload: 0 | 1
}

interface UpdateCreditsAction {
    type: typeof UPDATE_CREDITS,
    payload: string
}

interface UpdateDescriptionAction {
    type: typeof UPDATE_DESCRIPTION,
    payload: string
}

interface UpdateNameAction {
    type: typeof UPDATE_NAME,
    payload: string
}

interface UpdateProgressAction {
    type: typeof UPDATE_PROGRESS,
    payload: number
}

export type PictureActionTypes =
    AddCustomPicturesAction |
    AddFilesAction |
    AddSelectionAction |
    DeleteFileAction |
    DeleteSelectionAction |
    SetAddingAction |
    SetCropperAction |
    SetLimitAction |
    SetListAction |
    SetPageAction |
    SetPictureAction |
    SetRunningAction |
    SetSearchAction |
    ToggleCreateAction |
    ToggleKeepAction |
    UpdateAltAction |
    UpdateCreateTabAction |
    UpdateCreditsAction |
    UpdateDescriptionAction |
    UpdateNameAction |
    UpdateProgressAction;