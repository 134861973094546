import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Collapse, IconButton, styled, Tooltip, Typography } from "@mui/material";
import { useDrag } from "react-dnd";
import { MailTemplateVisualEditorContext } from "../mailTemplateVisualEditorContext";
import { RegisterBlockOptions } from "./registered-blocks";
import { AppState } from "../../../../../Reducers/Reducers";
import { selectVisualEditorBlock, setVisualEditorDraggedBlockId, unselectVisualEditorBlock } from "../redux/actions";
import { DropResult } from "../mailTemplateVisualEditorDropTarget";
import DragIcon from "@mui/icons-material/OpenWith";
import DuplicateIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";

export type BlockFeaturedComponentProps<T> = {
    id: number,
    sourceName: string,
    onDuplicate: () => void,
    onDelete: () => void,
    onDropped?: (item: DraggedItemData, dropResult: DropResult | null) => void,
    options: T,
}

export type DraggedItemData = {
    id: number,
    sourceName: string
}

export type ComponentWithBlockFeatures<T> = React.FunctionComponent<BlockFeaturedComponentProps<T>> | React.ComponentClass<BlockFeaturedComponentProps<T>>

export function withBlockFeatures<T>(blockOptions: RegisterBlockOptions<T>): ComponentWithBlockFeatures<T> {
    const Content = (props: BlockFeaturedComponentProps<T>) => {
        const { id, options } = props;
        const { t } = useTranslation();
        const dispatch = useDispatch();
        const [collected, drag, dragPreview] = useDrag<DraggedItemData, DropResult, {isDragged: boolean}>(() => ({
            type: blockOptions.type,
            item: {
                id,
                sourceName: props.sourceName
            },
            collect: (monitor) => ({
                isDragged: monitor.isDragging()
            }),
            end: (item, monitor) => {
                if (monitor.didDrop() && props.onDropped) {
                    props.onDropped(item, monitor.getDropResult());
                }
            }
        }), [props.onDropped]);
        const [hover, setHover] = useState(false);
        const selectedBlockId = useSelector((state: AppState) => state.mailTemplate.others.visualEditorSelectedBlockId);
        const draggedBlockId = useSelector((state: AppState) => state.mailTemplate.others.visualEditorDraggedBlockId);
        const onSelectBlock = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            event.stopPropagation();
            dispatch(selectVisualEditorBlock(id));
        };
        const onDeleteBlock = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.stopPropagation();
            props.onDelete();
            dispatch(unselectVisualEditorBlock());
        };
        const shouldHighlight = (hover && draggedBlockId === null) || selectedBlockId === id;

        useEffect(() => {
            if (collected.isDragged) {
                dispatch(setVisualEditorDraggedBlockId(id));
            } else {
                dispatch(setVisualEditorDraggedBlockId(null));
            }
        }, [collected.isDragged]);

        return (
            <MailTemplateVisualEditorContext.Consumer>
                {
                    (context) => (
                        <div
                            ref={dragPreview}
                            onMouseEnter={() => setHover(true)}
                            onMouseLeave={() => setHover(false)}
                            onMouseDown={onSelectBlock}
                        >
                            <Collapse orientation="vertical" in={shouldHighlight} easing="ease-in">
                                <Controls>
                                    <LeftControls>
                                        {
                                            !context.disableBlocksEdit &&
                                            <Tooltip title={t<string>('shared.mail-template-visual-editor-move')} placement="top">
                                                <ControlButton
                                                    ref={drag}
                                                    size="small"
                                                >
                                                    <DragIcon fontSize="small" />
                                                </ControlButton>
                                            </Tooltip>
                                        }
                                        <Typography>
                                            {t<string>(blockOptions.label)}
                                        </Typography>
                                    </LeftControls>
                                    {
                                        !context.disableBlocksEdit &&
                                        <div>
                                            <Tooltip title={t<string>('shared.mail-template-visual-editor-duplicate')} placement="top">
                                                <ControlButton size="small" onMouseDown={props.onDuplicate}>
                                                    <DuplicateIcon fontSize="small" />
                                                </ControlButton>
                                            </Tooltip>
                                            <Tooltip title={t<string>('shared.mail-template-visual-editor-delete')} placement="top">
                                                <ControlButton
                                                    size="small"
                                                    onMouseDown={onDeleteBlock}>
                                                    <DeleteIcon fontSize="small" />
                                                </ControlButton>
                                            </Tooltip>
                                        </div>
                                    }
                                </Controls>
                            </Collapse>
                            <ContentContainer highlight={shouldHighlight}>
                                <blockOptions.component id={id} options={options} />
                            </ContentContainer>
                        </div>
                    )
                }
            </MailTemplateVisualEditorContext.Consumer>
        );
    };
    Content.displayName = `WithBlockFeatures(${blockOptions.component.name})`;
    return Content;
}

const Controls = styled('div')((props) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: props.theme.spacing(1),
    paddingBottom: props.theme.spacing(1),
    paddingLeft: props.theme.spacing(2),
    paddingRight: props.theme.spacing(2),
    backgroundColor: props.theme.palette.primary.main,
    color: props.theme.palette.primary.contrastText
}));

const LeftControls = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const ControlButton = styled(IconButton)((props) => ({
    color: props.theme.palette.primary.contrastText
}));

const ContentContainer = styled(
    'div',
    {
        shouldForwardProp(prop) {
            return prop !== 'highlight';
        }
    }
)<{highlight: boolean}>((props) => ({
    border: props.highlight ?
        `2px solid ${props.theme.palette.primary.main}` :
        undefined,
    cursor: 'pointer',
    transition: 'border-color 0.5s'
}));
