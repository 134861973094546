// Dependencies
import {
    createAction,
    createSlice,
} from "@reduxjs/toolkit";
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { FlightState } from "../objects/flightState";
import { IataAirport } from "../../iata/objects/iataAirport";
import { Event } from "../../event/objects/event";
import {
    intervalToDuration,
    subYears,
} from "date-fns";
import { FlightPassenger } from "../objects/flightPassenger";
import { IataCountry } from "../../iata/objects/iataCountry";
import { IataAirline } from "../../iata/objects/iataAirline";
import { FlightSeat } from "../objects/flightSeat";
import { FlightMoreOption } from "../objects/flightMoreOption";

const setEvent = createAction<Event>('event/setEvent');

const paris_aiport: IataAirport = {
    "id": 13222,
    "iata_city": {
        "id": 11663,
        "iata_country": {
            "id": 329,
            "country_code": "FR",
            "country_code_alpha3": "FRA",
            "iso_3166_1_numeric": null,
            "international_name": "France",
            "international_nationality": "French",
            "phone_code": null,
            "tsv": null,
            "name": "France",
            "nationality": "Française"
        },
        "city_code": "PAR",
        "international_name": "Paris",
        "tsv": null,
        "name": "Paris"
    },
    "airport_code": "cPAR",
    "icao_code": null,
    "weight": 0,
    "international_name": "All airports (PAR) - France",
    "tsv": null,
    "latitude": null,
    "longitude": null,
    "name": "tous les aéroports (PAR) - France"
};

const nice_airport: IataAirport = {
    "id": 10919,
    "iata_city": {
        "id": 11267,
        "iata_country": {
            "id": 329,
            "country_code": "FR",
            "country_code_alpha3": "FRA",
            "iso_3166_1_numeric": null,
            "international_name": "France",
            "international_nationality": "French",
            "phone_code": null,
            "tsv": null,
            "name": "France",
            "nationality": "Française"
        },
        "city_code": "NCE",
        "international_name": "Nice",
        "tsv": null,
        "name": "Nice"
    },
    "airport_code": "NCE",
    "icao_code": "LFMN",
    "weight": 2,
    "international_name": "Cote D Azur Arpt",
    "tsv": null,
    "latitude": "43.658401489300",
    "longitude": "7.215869903560",
    "name": "Nice Cote d'Azur"
};

const initialState: FlightState = {
    cart: [],
    airports: null,
    search_airlines: [],
    adt: 1,
    cnn: 0,
    inf: 0,
    search_type: 0,
    direct_flight_only: false,
    one_stopover: false,
    flight: null,
    option: null,
    flights: [],
    flight_loading: false,
    selected_option: null,
    service_catalogue: null,
    original_flights: [],
    lowest_companies: [],
    flight_sort: 'asc',
    filtered_company: null,
    filter_bag: null,
    passengers_error: 0,
    filter_airports: [],
    hidden_flights: [],
    passengers: [],
    seat_map: [],
    step: 1,
    legs: [{
        arrival_airport: nice_airport,
        departure_airport: paris_aiport,
        date: new Date(),
        flight_class: 0,
    }, {
        arrival_airport: paris_aiport,
        departure_airport: nice_airport,
        date: new Date(),
        flight_class: 0,
    }],
    open_flight_class: false,
    manual_request: ''
};

const getMasterPrice = (prices) => {
    for (let i = 0; i < prices.length; i++) {
        if (prices[i].master_price) {
            return prices[i].selling_price;
        }
    }
};
const getPrice = (prices) => {
    for (let i = 0; i < prices.length; i++) {
        if (prices[i].is_tva) {
            return prices[i].selling_price;
        }
    }
    for (let i = 0; i < prices.length; i++) {
        if (prices[i].master_price) {
            return prices[i].selling_price;
        }
    }
};

const flightFilter = (state) => {
    const hidden_flights: number[] = [];
    state.flights.map(flight => {
        let has_bag = true;
        let has_airline = false;
        flight.outbounds.map(outbound => {
            outbound.legs.map(leg => {
                if (leg.baggage_allowance.number_of_pieces !== state.filter_bag) {
                    has_bag = false;
                }
            });
            if (outbound.first_leg.marketing_airline.id === state.filtered_company) {
                has_airline = true;
            }
        });
        if ((state.filter_bag !== null && has_bag === false) ||
      (state.filtered_company !== null && !has_airline)) {
            hidden_flights.push(flight.fake_id);
        }
    });
    return hidden_flights;
};

export const flightSlice = createSlice({
    name: 'flight',
    initialState,
    reducers: {
        setFlightCart: (state, action) => {
            console.log("action.payload : ", action.payload);
            state.cart = action.payload;
        },
        addPassengerOption: (state, action: PayloadAction<{key: string, passenger_index: number}>) => {
            const { passenger_index, key } = action.payload;
            const passengers = [...state.passengers];
            const passenger = {...passengers[passenger_index]};
            if (passenger.option_selected[key]) {
                const option_selected = [...passenger.option_selected[key]];
                option_selected.push({ssr_code: "none"});
                passenger.option_selected[key] = option_selected;
            } else {
                passenger.option_selected[key] = [{ssr_code: "none"}];
            }
            passengers[passenger_index] = passenger;
            state.passengers = passengers;
        },
        deletePassengerOption: (state, action: PayloadAction<{key: string, passenger_index: number, option_index: number}>) => {
            const { passenger_index, key, option_index } = action.payload;
            const passengers = [...state.passengers];
            const passenger = {...passengers[passenger_index]};
            if (passenger.option_selected[key]) {
                const option_selected = [...passenger.option_selected[key]];
                option_selected.splice(option_index, 1);
                passenger.option_selected[key] = option_selected;
            }
            passengers[passenger_index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerOption: (state, action: PayloadAction<{key: string, passenger_index: number, option_index: number, value: FlightMoreOption | {ssr_code: "none"}}>) => {
            const { passenger_index, key, option_index, value } = action.payload;
            const passengers = [...state.passengers];
            const passenger = {...passengers[passenger_index]};
            if (passenger.option_selected[key]) {
                const option_selected = [...passenger.option_selected[key]];
                option_selected[option_index] = value;
                passenger.option_selected[key] = option_selected;
            }
            passengers[passenger_index] = passenger;
            state.passengers = passengers;
        },
        setAirports: (state, action: PayloadAction<IataAirport[]>) => {
            state.airports = action.payload;
        },
        setSeatMap: (state, action) => {
            state.seat_map = action.payload.flight;
        },
        setFlightLoading: (state, action: PayloadAction<boolean>) => {
            state.flight_loading = action.payload;
        },
        setPassengerSeat: (state, action: PayloadAction<{current_passenger: number, current_flight: string, seat: FlightSeat}>) => {
            const { current_passenger, current_flight, seat } = action.payload;
            const passengers = [...state.passengers];
            const passenger = {...passengers[current_passenger]};
            passenger.seat_selected[current_flight] = seat;
            passengers[current_passenger] = passenger;
            state.passengers = passengers;
        },
        setFlightSelected: (state, action: PayloadAction<{flight: any, option: any}>) => {
            state.flight = action.payload.flight;
            if (action.payload.option.fares.length > 0) {
                let added = false;
                action.payload.option.fares.at(-1).option_category.at(0).option_segment.map(segment => {
                    return {
                        ...segment,
                        option: segment.option.sort((a, b) => {
                            if (a.option_type === b.option_type) {
                                return 0;
                            } else if (a.option_type === "BG") {
                                return -1;
                            } else if (a.option_type === "BF") {
                                return 1;
                            } else if (b.option_type === "BG") {
                                return 1;
                            } else if (b.option_type === "BF") {
                                return -1;
                            } else {
                                return 0;
                            }
                        })
                    };
                });
                for (let i = 0; i < action.payload.option.fares.length; i++) {
                    let total = 0;
                    let total_master_flight_ht = 0;
                    let total_master_taxe = 0;
                    let price_per_pax = 0;
                    let adult_price = 0;
                    let adult_taxe = 0;
                    let adult_total_price = 0;
                    let child_price = 0;
                    let child_taxe = 0;
                    let child_total_price = 0;
                    let infant_price = 0;
                    let infant_taxe = 0;
                    let infant_total_price = 0;
                    action.payload.option.fares.at(i).pax_pricing.map(pax_pricing => {
                        if (pax_pricing.pax_code === "ADT") {
                            total_master_flight_ht += (parseFloat(getMasterPrice(pax_pricing.prices)) * state.adt);
                            total_master_taxe += (parseFloat(getMasterPrice(pax_pricing.taxes)) * state.adt);
                            adult_price = parseFloat(getPrice(pax_pricing.prices));
                            adult_taxe = parseFloat(getPrice(pax_pricing.taxes));
                        } else if (pax_pricing.pax_code === "CNN") {
                            total_master_flight_ht += (parseFloat(getMasterPrice(pax_pricing.prices)) * state.cnn);
                            total_master_taxe += (parseFloat(getMasterPrice(pax_pricing.taxes)) * state.cnn);
                            child_price = parseFloat(getPrice(pax_pricing.prices));
                            child_taxe = parseFloat(getPrice(pax_pricing.taxes));
                        } else if (pax_pricing.pax_code === "INF") {
                            total_master_flight_ht += (parseFloat(getMasterPrice(pax_pricing.prices)) * state.inf);
                            total_master_taxe += (parseFloat(getMasterPrice(pax_pricing.taxes)) * state.inf);
                            infant_price = parseFloat(getPrice(pax_pricing.prices));
                            infant_taxe = parseFloat(getPrice(pax_pricing.taxes));
                        }
                    });
                    adult_total_price = (adult_price + adult_taxe) * state.adt;
                    child_total_price = (child_price + child_taxe) * state.cnn;
                    infant_total_price = (infant_price + infant_taxe) * state.inf;
                    total = adult_total_price + child_total_price + infant_total_price;
                    price_per_pax = total / (state.adt + state.cnn + state.inf);
                    action.payload.option.fares.at(i).total_master_flight_ht = total_master_flight_ht;
                    action.payload.option.fares.at(i).total_master_taxe = total_master_taxe;
                    action.payload.option.fares.at(i).adult_price = adult_price;
                    action.payload.option.fares.at(i).adult_taxe = adult_taxe;
                    action.payload.option.fares.at(i).adult_total_price = adult_total_price;
                    action.payload.option.fares.at(i).child_price = child_price;
                    action.payload.option.fares.at(i).child_taxe = child_taxe;
                    action.payload.option.fares.at(i).child_total_price = child_total_price;
                    action.payload.option.fares.at(i).infant_price = infant_price;
                    action.payload.option.fares.at(i).infant_taxe = infant_taxe;
                    action.payload.option.fares.at(i).infant_total_price = infant_total_price;
                    action.payload.option.fares.at(i).total = total;
                    action.payload.option.fares.at(i).price_per_pax = price_per_pax;
                    action.payload.option.fares.at(i).option_category.at(0).option_segment = action.payload.option.fares.at(i).option_category.at(0).option_segment.map((segment, segment_index) => {
                        let options = [...action.payload.option.fares.at(-1).option_category.at(0).option_segment[segment_index].option];
                        console.log('options : ', options);
                        options = options.map(option => {
                            return {
                                ...option,
                                available: !!segment.option.find(old_option => old_option.ssr_code === option.ssr_code),
                            };
                        });
                        return {
                            ...segment,
                            option: options,
                            // option: segment.option.sort((a, b) => {
                            //   if (a.option_type === b.option_type) {
                            //     return 0;
                            //   } else if (a.option_type === "BG") {
                            //     return -1;
                            //   } else if (a.option_type === "BF") {
                            //     return 1;
                            //   } else if (b.option_type === "BG") {
                            //     return 1;
                            //   } else if (b.option_type === "BF") {
                            //     return -1;
                            //   } else {
                            //     return 0;
                            //   }
                            // })
                        };
                    });
                    if (action.payload.option.fares[i].option_category.length > 0 && !added) {
                        added = true;
                        state.selected_option = action.payload.option.fares.at(i);
                        state.service_catalogue = null;
                    }
                }
            }
            state.option = action.payload.option;
            const passengers: FlightPassenger[] = [];
            for (let i = 0; i < state.adt; i++) {
                passengers.push({
                    type: "ADT",
                    title: "MR",
                    first_name: "",
                    last_name: "",
                    birth_date: subYears(new Date(), 30),
                    nationality: null,
                    phone_number: "",
                    email: "",
                    identity_document_type: "none",
                    identity_document_number: "",
                    identity_document_country: null,
                    identity_document_expiration_date: null,
                    seat_selected: {},
                    option_selected: {},
                });
            }
            for (let i = 0; i < state.cnn; i++) {
                passengers.push({
                    type: "CNN",
                    title: "MR",
                    first_name: "",
                    last_name: "",
                    birth_date: subYears(new Date(), 5),
                    nationality: null,
                    phone_number: "",
                    email: "",
                    identity_document_type: "none",
                    identity_document_number: "",
                    identity_document_country: null,
                    identity_document_expiration_date: null,
                    seat_selected: {},
                    option_selected: {},
                });
            }
            for (let i = 0; i < state.inf; i++) {
                passengers.push({
                    type: "INF",
                    title: "MR",
                    first_name: "",
                    last_name: "",
                    birth_date: subYears(new Date(), 1),
                    nationality: null,
                    phone_number: "",
                    email: "",
                    identity_document_type: "none",
                    identity_document_number: "",
                    identity_document_country: null,
                    identity_document_expiration_date: null,
                    seat_selected: {},
                    option_selected: {},
                });
            }
            state.passengers = passengers;
            state.step = 1;
        },
        selectOption: (state, action) => {
            state.selected_option = action.payload;
            state.service_catalogue = null;
        },
        setOpenFlightClass: (state, action) => {
            state.open_flight_class = action.payload;
        },
        setServiceCatalogue: (state, action) => {
            let passengers = [...state.passengers];
            Object.keys(action.payload.segments).forEach((key, index) => {
                passengers = passengers.map((passenger, passenger_index) => {
                    if (!passenger.option_selected[key]) {
                        const option_selected = {...passenger.option_selected};
                        option_selected[key] = [{ssr_code: "none"}];
                        return {
                            ...passenger,
                            option_selected: option_selected,
                        };
                    }
                    return passenger;
                });
            });
            state.passengers = passengers;
            state.service_catalogue = action.payload;
        },
        setStep: (state, action: PayloadAction<0 | 1 | 2 | 3 | 4 | 5>) => {
            if (action.payload > 2) {
                let passenger_filled = true;
                state.passengers.map(passenger => {
                    if (passenger.first_name === "" || passenger.last_name === "") {
                        passenger_filled = false;
                    }
                });
                if (passenger_filled) {
                    state.step = action.payload;
                }
                state.passengers_error = !passenger_filled ? state.passengers_error + 1 : 0;
            } else {
                state.step = action.payload;
            }
        },
        updateFlightSort: (state, action: PayloadAction<'asc' | 'dsc'>) => {
            state.flight_sort = action.payload;
            if (action.payload === 'asc') {
                state.flights = state.flights.toSorted((a, b) => a.total - b.total);
            } else {
                state.flights = state.flights.toSorted((a, b) => b.total - a.total);
            }
        },
        updateSearchAirlines: (state, action: PayloadAction<IataAirline[]>) => {
            state.search_airlines = action.payload;
        },
        updateSearchType: (state, action: PayloadAction<0 | 1 | 2 | 3>) => {
            state.search_type = action.payload;
            const legs_length: number = state.legs.length;
            switch (action.payload) {
                case 0: {
                    if (legs_length === 2) {
                        break;
                    } else if (state.legs.length > 2) {
                        state.legs = state.legs.slice(0 , 2);
                        break;
                    } else {
                        const legs = [...state.legs];
                        while (legs.length < 2) {
                            legs.push({
                                arrival_airport: legs[0].departure_airport,
                                departure_airport: legs[0].arrival_airport,
                                date: new Date(legs[0].date),
                                flight_class: 0,
                            });
                        }
                        state.legs = legs;
                        break;
                    }
                }
                case 1: {
                    if (legs_length === 1) {
                        break;
                    } else if (legs_length > 1) {
                        state.legs = state.legs.slice(0 , 1);
                        break;
                    } else {
                        const legs = [...state.legs];
                        while (legs.length < 1) {
                            legs.push({
                                arrival_airport: null,
                                departure_airport: null,
                                date: new Date(),
                                flight_class: 0,
                            });
                        }
                        state.legs = legs;
                        break;
                    }
                }
                case 2: {
                    const legs = [...state.legs];
                    while (legs.length < 1) {
                        legs.push({
                            arrival_airport: null,
                            departure_airport: null,
                            date: new Date(),
                            flight_class: 0,
                        });
                    }
                    state.legs = legs;
                    break;
                }
            }
        },
        updateComp: (state, action: PayloadAction<{type: "adt" | "cnn" | "inf", value: 1 | -1}>) => {
            const { type, value } = action.payload;
            if (type === "adt") {
                if (!(value === -1 && state.adt < 2) && !(value === 1 && state.adt > 8)) {
                    state.adt = state.adt + value;
                }
            } else if (type === "cnn") {
                if (!(value === -1 && state.cnn < 1) && !(value === 1 && state.cnn > 8)) {
                    state.cnn = state.cnn + value;
                }
            } else {
                if (!(value === -1 && state.inf < 1) && !(value === 1 && state.inf > 8)) {
                    state.inf = state.inf + value;
                }
            }
        },
        updatePassengerTitle: (state, action: PayloadAction<{value: "MR" | "MRS" | "MSS" | "MX", index: number}>) => {
            const { value, index } = action.payload;
            const passengers = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.title = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerFirstName: (state, action: PayloadAction<{value: string, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.first_name = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerLastName: (state, action: PayloadAction<{value: string, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.last_name = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerBirthDate: (state, action: PayloadAction<{value: Date, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.birth_date = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerPhoneNumber: (state, action: PayloadAction<{value: string, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.phone_number = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerEmail: (state, action: PayloadAction<{value: string, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.email = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerNationality: (state, action: PayloadAction<{value: null | IataCountry, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.nationality = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerIdentityDocumentCountry: (state, action: PayloadAction<{value: null | IataCountry, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.identity_document_country = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerIdentityDocumentType: (state, action: PayloadAction<{value: "none" | "CARD" | "PASSPORT", index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.identity_document_type = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerIdentityDocumentNumber: (state, action: PayloadAction<{value: string, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.identity_document_number = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updatePassengerIdentityDocumentExpirationDate: (state, action: PayloadAction<{value: Date, index: number}>) => {
            const { value, index } = action.payload;
            const passengers: FlightPassenger[] = [...state.passengers];
            const passenger = {...passengers[index]};
            passenger.identity_document_expiration_date = value;
            passengers[index] = passenger;
            state.passengers = passengers;
        },
        updateLegAirport: (state, action: PayloadAction<{
      airport: null | IataAirport,
      type: 'departure' | 'arrival',
      leg_index: number
    }>) => {
            const { airport, type, leg_index } = action.payload;
            let legs = [...state.legs];
            const leg = {...state.legs.at(leg_index)};
            if (type === 'departure') {
                leg.departure_airport = airport;
                if (state.search_type === 0 && leg_index === 0 && legs.at(1)?.arrival_airport === null) {
                    const second_leg = {...state.legs.at(1)};
                    second_leg.arrival_airport = airport;
                    legs = legs.with(1, second_leg);
                }
            } else {
                leg.arrival_airport = airport;
                if (state.search_type === 0 && leg_index === 0 && legs.at(1)?.departure_airport === null) {
                    const second_leg = {...state.legs.at(1)};
                    second_leg.departure_airport = airport;
                    legs = legs.with(1, second_leg);
                }
            }
            state.legs = legs.with(leg_index, leg);
        },
        updateLegDate: (state, action: PayloadAction<{date: Date, leg_index: number}>) => {
            const { date, leg_index } = action.payload;
            const leg = {...state.legs.at(leg_index)};
            leg.date = date;
            state.legs = state.legs.with(leg_index, leg);
        },
        updateFlightClass: (state, action: PayloadAction<{
      value: 0 | 1 | 2 | 3 | 4,
      leg_index: number
    }>) => {
            const { value, leg_index } = action.payload;
            const leg = {...state.legs.at(leg_index)};
            leg.flight_class = value;
            state.legs = state.legs.with(leg_index, leg);
        },
        toggleDirectFlightOnly: (state) => {
            state.direct_flight_only = !state.direct_flight_only;
        },
        toggleOneStopover: (state) => {
            state.one_stopover = !state.one_stopover;
        },
        toggleCompany: (state, action: PayloadAction<number>) => {
            state.filtered_company = state.filtered_company === action.payload ? null : action.payload;
            state.hidden_flights = flightFilter(state);
        },
        filterBag: (state, action: PayloadAction<number>) => {
            state.filter_bag = state.filter_bag === action.payload ? null : action.payload;
            state.hidden_flights = flightFilter(state);
        },
        toggleAirport: (state, action: PayloadAction<number>) => {
            const filter_airports = [...state.filter_airports];
            const index = filter_airports.indexOf(action.payload);
            if (index === -1) {
                filter_airports.push(action.payload);
            } else {
                filter_airports = filter_airports.splice(index, 1);
            }
            state.filter_airports = filter_airports;
            state.hidden_flights = flightFilter(state);
        },
        setFlightResponse: (state, action) => {
            const flights = [];
            let companies_price = [];
            if (action.payload.flight && Array.isArray(action.payload.flight)) {
                action.payload.flight.map(flight => {
                    let total = 0;
                    let adult_price = 0;
                    let adult_taxe = 0;
                    let adult_total_price = 0;
                    let child_price = 0;
                    let child_taxe = 0;
                    let child_total_price = 0;
                    let infant_price = 0;
                    let infant_taxe = 0;
                    let infant_total_price = 0;
                    flight.pax_pricing.map(pax_pricing => {
                        if (pax_pricing.pax_code === "ADT") {
                            adult_price = parseFloat(getPrice(pax_pricing.prices));
                            adult_taxe = parseFloat(getPrice(pax_pricing.taxes));
                        } else if (pax_pricing.pax_code === "CNN") {
                            child_price = parseFloat(getPrice(pax_pricing.prices));
                            child_taxe = parseFloat(getPrice(pax_pricing.taxes));
                        } else if (pax_pricing.pax_code === "INF") {
                            infant_price = parseFloat(getPrice(pax_pricing.prices));
                            infant_taxe = parseFloat(getPrice(pax_pricing.taxes));
                        }
                    });
                    adult_total_price = (adult_price + adult_taxe) * state.adt;
                    child_total_price = (child_price + child_taxe) * state.cnn;
                    infant_total_price = (infant_price + infant_taxe) * state.inf;
                    total = adult_total_price + child_total_price + infant_total_price;
                    const new_flight = {
                        fake_id: Math.floor(Math.random() * 1000000),
                        fare_type: flight.fare_type,
                        flight_key: flight.flight_key,
                        flight_source: flight.flight_source,
                        last_ticketing_date: flight.last_ticketing_date,
                        no_time_data: flight.no_time_data,
                        adult_price: adult_price,
                        adult_taxe: adult_taxe,
                        adult_total_price: adult_total_price,
                        child_price: child_price,
                        child_taxe: child_taxe,
                        child_total_price: child_total_price,
                        infant_price: infant_price,
                        infant_taxe: infant_taxe,
                        infant_total_price: infant_total_price,
                        price_per_pax: total / (state.adt + state.cnn + state.inf),
                        total: total,
                        outbounds: []
                    };
                    flight.outbound_list.map(outbound => {
                        let legs = [];
                        outbound.choices.at(0).legs.map(leg => {
                            console.log("leg : ", leg);
                            legs.push({
                                ...leg,
                                fake_id: Math.floor(Math.random() * 1000000),
                                destination_airport: leg.destination_airport ? action.payload.airports[leg.destination_airport] : undefined,
                                destination_station: leg.destination_station ? action.payload.stations[leg.destination_station] : undefined,
                                marketing_airline: action.payload.airlines[leg.marketing_airline],
                                operating_airline: action.payload.airlines[leg.operating_airline],
                                origin_airport: leg.origin_airport ? action.payload.airports[leg.origin_airport] : undefined,
                                origin_station: leg.origin_station ? action.payload.stations[leg.origin_station] : undefined,
                                duration_formatted: intervalToDuration({ start: 0, end: leg.flight_time * 60 * 1000 }),
                            });
                        });
                        // console.log('outbound.choices.at(0).flight_duration : ', outbound.choices.at(0).flight_duration);
                        // console.log('intervalToDuration({ start: 0, end: outbound.choices.at(0).flight_duration }) : ', intervalToDuration({ start: 0, end: outbound.choices.at(0).flight_duration * 60 * 1000 }));
                        let new_outbound = {
                            fake_id: Math.floor(Math.random() * 1000000),
                            duration_days: outbound.choices.at(0).duration_days,
                            flight_duration: outbound.choices.at(0).flight_duration,
                            duration_formatted: intervalToDuration({ start: 0, end: outbound.choices.at(0).flight_duration * 60 * 1000 }),
                            legs: legs,
                            leg_length: legs.length,
                            first_leg: legs.at(0),
                            last_leg: legs.at(-1),
                        };
                        companies_price.push({
                            marketing_airline: new_outbound.first_leg.marketing_airline,
                            price_per_pax: new_flight.price_per_pax,
                        });
                        new_outbound.legs = legs;
                        new_flight.outbounds.push(new_outbound);
                    });
                    flights.push(new_flight);
                });
            }
            companies_price = companies_price.sort((a, b) => a.price_per_pax - b.price_per_pax);
            const lowest_companies = [];
            companies_price.map(company => {
                const pos = lowest_companies.map(lowest => lowest.marketing_airline.id).indexOf(company.marketing_airline.id);
                if (pos === -1) {
                    lowest_companies.push(company);
                }
            });
            state.flight_loading = false;
            state.flights = flights.toSorted((a, b) => a.total - b.total);
            state.original_flights = state.flights;
            state.lowest_companies = lowest_companies;
        },
        resetFlight: (state) => {
            state.airports = null;
            state.search_airlines = [];
            state.flight = null;
            state.option = null;
            state.flights = [];
            state.original_flights = [];
            state.lowest_companies = [];
            state.flight_sort = 'asc';
            state.filtered_company = null;
            state.filter_bag = null;
            state.filter_airports = [];
            state.hidden_flights = [];
        },
        swapLegs: (state, action) => {
            state.legs = action.payload;
            // state.legs = state.legs.reverse();
        },
        addOutbounds: (state) => {
            const legs = [...state.legs];
            legs.push({
                arrival_airport: null,
                departure_airport: legs[legs.length - 1].arrival_airport,
                date: new Date(legs[legs.length - 1].date),
                flight_class: legs[legs.length - 1].flight_class,
            });
            state.legs = legs;
        },
        removeOutbound: (state, action) => {
            state.legs = state.legs.filter((_, index) => index !== action.payload);
        },
        updateManualRequest: (state, action) => {
            state.manual_request = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(setEvent, (state, action: PayloadAction<Event>) => {
            const { event_start_date, event_end_date } = action.payload;
            if (state.search_type === 0 && event_start_date !== null && event_end_date !== null) {
                let legs = [...state.legs];
                const leg = {...state.legs.at(0)};
                const second_leg = {...state.legs.at(1)};
                leg.date = new Date(event_start_date);
                second_leg.date = new Date(event_end_date);
                legs = legs.with(0, leg);
                state.legs = legs.with(1, second_leg);
            }
        });
    }
});

export const {
    updateManualRequest,
    addOutbounds,
    removeOutbound,
    setFlightCart,
    addPassengerOption,
    deletePassengerOption,
    updatePassengerOption,
    setAirports,
    setSeatMap,
    setFlightLoading,
    setPassengerSeat,
    setFlightSelected,
    selectOption,
    setOpenFlightClass,
    setServiceCatalogue,
    setStep,
    updateFlightSort,
    updateSearchAirlines,
    updateSearchType,
    updateLegDate,
    updateFlightClass,
    updateComp,
    updatePassengerTitle,
    updatePassengerFirstName,
    updatePassengerLastName,
    updatePassengerBirthDate,
    updatePassengerPhoneNumber,
    updatePassengerEmail,
    updatePassengerNationality,
    updatePassengerIdentityDocumentCountry,
    updatePassengerIdentityDocumentType,
    updatePassengerIdentityDocumentNumber,
    updatePassengerIdentityDocumentExpirationDate,
    updateLegAirport,
    toggleDirectFlightOnly,
    toggleCompany,
    toggleOneStopover,
    filterBag,
    toggleAirport,
    setFlightResponse,
    resetFlight,
    swapLegs,
} = flightSlice.actions;

export default flightSlice.reducer;