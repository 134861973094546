// Dependencies
import React, { FC, ReactElement } from 'react';
// Core & Lab
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';

const Loading: FC = (): ReactElement => {
    return (
        <Grid className={ 'loading' } container justifyContent={ 'center' } alignItems={ 'center' }>
            <Grid item>
                <CircularProgress size={ 100 }/>
            </Grid>
        </Grid>
    );
};

export default Loading;