import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
const languageDetector = new LanguageDetector();
languageDetector.addDetector({
    name: 'facilitatripLanguageDetector',
    lookup(options) {
        if (navigator.language?.includes('en')) {
            return 'en';
        }
        if (navigator.language?.includes('fr')) {
            return 'fr';
        }
        return  navigator.language;
    },
});
i18n.use(Backend).use(languageDetector).use(initReactI18next).init({
    fallbackLng: 'en',
    preload: ['en', 'fr'],
    backend: {
        loadPath: `/translation/language/{{lng}}.json`
        // loadPath: `${API_HREF}/phrase/test_file/`
    },
    joinArrays: '\n',
    debug: true,
    interpolation: {
        escapeValue: false,
    },
    detection: {order:['facilitatripLanguageDetector', 'querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain']},
    supportedLngs: ['en', 'fr'],
});
export default i18n;