// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { User } from '../../user/objects/user';
import { ProfileState } from '../objects/profileState';

const initialState: ProfileState = {
    id: null,
    profile: null,
    retrieved: false,
    token: null
};

export const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        setLogin: (state, action: PayloadAction<{token: string, user: User}>) => {
            const { token, user } = action.payload;
            state.id = user.id;
            state.profile = user;
            state.retrieved = true;
            state.token = token;
        },
        setProfile: (state, action: PayloadAction<null | User>) => {
            if (action.payload === null) {
                state.retrieved = false;
                state.profile = null;
                state.id = null;
                state.token = null;
            }
            state.retrieved = true;
            state.profile = action.payload;
        },
        updateProfile: (state, action: PayloadAction<null | User>) => {
            state.profile = action.payload;
        }
    }
});

export const {
    setLogin,
    setProfile,
    updateProfile
} = profileSlice.actions;

export default profileSlice.reducer;