import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorColorPicker } from "./mailTemplateVisualEditorColorPicker";

type Props = {
    title?: string,
    backgroundColor: string,
    onChangeBackgroundColor: (value: string) => void
}

export function MailTemplateVisualEditorBackgroundColorOption(props: Props): JSX.Element {
    const { t } = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>
                {props.title ?? t<string>('shared.mail-template-visual-editor-background-color')}
            </Typography>
            <MailTemplateVisualEditorColorPicker
                color={props.backgroundColor}
                onChangeColor={props.onChangeBackgroundColor}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}
