import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorNumberPicker } from "./mailTemplateVisualEditorNumberPicker";

type Props = {
    width: number,
    onChangeWidth: (width: number) => void,
}

export function MailTemplateVisualEditorWidthOption(props: Props): JSX.Element {
    const {t} = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t('shared.mail-template-visual-editor-width')}</Typography>
            <MailTemplateVisualEditorNumberPicker
                measureUnit="%"
                value={props.width}
                onChange={props.onChangeWidth}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}