// Dependencies
import { createSlice } from '@reduxjs/toolkit';
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';
// Types
import { LoadingState } from '../objects/loadingState';

const initialState: LoadingState = {
    loading: false
};

export const loadingSlice = createSlice({
    name: 'loading',
    initialState,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const {
    setLoading
} = loadingSlice.actions;

export default loadingSlice.reducer;