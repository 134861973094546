import React from "react";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import { MailTemplateVisualEditorOptionsSection } from "./mailTemplateVisualEditorOptionsSection";
import { MailTemplateVisualEditorNumberPicker } from "./mailTemplateVisualEditorNumberPicker";

type Props = {
    thickness: number,
    onChangeThickness: (thickness: number) => void,
}

export function MailTemplateVisualEditorThicknessOption(props: Props): JSX.Element {
    const {t} = useTranslation();
    return (
        <MailTemplateVisualEditorOptionsSection>
            <Typography>{t('shared.mail-template-visual-editor-thickness')}</Typography>
            <MailTemplateVisualEditorNumberPicker
                measureUnit="px"
                value={props.thickness}
                onChange={props.onChangeThickness}
            />
        </MailTemplateVisualEditorOptionsSection>
    );
}