import { createSlice } from "@reduxjs/toolkit";
import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

import { Payment } from "../object/payment";

const initialState: Payment = {
    amount: 0,
    type: ""
};
export const paymentSlice = createSlice({
    name: 'payment',
    initialState,
    reducers: {
        updatePayment: (state, action: PayloadAction<Payment>) => {
            state.amount = action.payload.amount;
            state.type = action.payload.type;
        }
    }
});

export const {
    updatePayment
} = paymentSlice.actions;

export default paymentSlice.reducer;