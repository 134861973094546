import { unionBy } from "lodash";
import { EditorState, ContentState } from "draft-js";
import { customColors } from "../../../utils/editor/customColors";
import { RichEditorStyleMap } from "../../../utils/editor/editor";
import { restoreRichEditorStateFromHtml } from "../../../utils/editor/restoreRichEditorStateFromHtml";
import { htmlWalker, MailTemplateColumnsBlockHtmlWalkingStrategy } from "../../utils/html-walker";
import { isAnchor } from "../../utils/isAnchor";
import { rgbToHex } from "../../utils/rgbToHex";
import { ColumnOptions, ColumnsBlock, createColumn } from "../mailTemplateVisualEditorColumnsBlock";
import { defaultIcons, IconData, SocialBlock } from "../mailTemplateVisualEditorSocialBlock";
import { Alignment } from "../../mailTemplateVisualEditorAlignmentOption";
import { MailTemplateVisualEditorBorderStyle } from "../../mailTemplateVisualEditorBorderStyleOption";
import { VerticalAlignment } from "../../mailTemplateVisualEditorVerticalAlignmentOption";
import { Block } from "../../objects/block";
import { BlockRestorer } from "../../objects/blockRestorer";
import { ButtonBlock } from "../mailTemplateVisualEditorButtonBlock";
import { DividerBlock } from "../mailTemplateVisualEditorDividerBlock";
import { ImageBlock } from "../mailTemplateVisualEditorImageBlock";
import { SpacerBlock } from "../mailTemplateVisualEditorSpacerBlock";
import { TextBlock } from "../mailTemplateVisualEditorTextBlock";

export const versions_0_0_0 = {
    text: {
        restore(options): TextBlock {
            let element = document.createElement('div');
            element.innerHTML = options.html;
            element = element.querySelector('.text-block') as HTMLDivElement;
            const styles = {
                ...RichEditorStyleMap,
                ...(options.quotationCode ? customColors[options.quotationCode] : null)
            };
            const editorState = restoreRichEditorStateFromHtml(
                styles,
                element.innerHTML
            );

            const block = new TextBlock();
            block.setOptions({
                editorState,
                backgroundColor: element?.style.backgroundColor ?? '#fff',
                borderStyle: (element?.style.borderStyle as any) ?? 'solid',
                borderWidth: parseInt(element?.style.borderWidth ?? '0'),
                borderCorners: {
                    topLeft: parseInt(element?.style.borderTopLeftRadius ?? '0'),
                    topRight: parseInt(element?.style.borderTopRightRadius ?? '0'),
                    bottomLeft: parseInt(element?.style.borderBottomLeftRadius ?? '0'),
                    bottomRight: parseInt(element?.style.borderBottomRightRadius ?? '0')
                },
                borderColor: element?.style.borderColor ?? '#000',
                padding: {
                    top: parseInt(element?.style.paddingTop ?? '5'),
                    bottom: parseInt(element?.style.paddingBottom ?? '5'),
                    left: parseInt(element?.style.paddingLeft ?? '5'),
                    right: parseInt(element?.style.paddingRight ?? '5')
                }
            });
            return block;
        }
    },
    button: {
        restore(options): ButtonBlock {
            const template: HTMLTemplateElement | null = document.createElement('template');
            template.innerHTML = options.html.trim();
            let element = template.content.firstChild as HTMLTableCellElement | null;
            element = element?.classList.contains('button-block') ?
                element :
            element?.querySelector('.button-block') as HTMLTableCellElement | null;
            const paragraphElement = element?.querySelector('p') ?? element?.querySelector('a');
            const containerElement = paragraphElement?.parentElement as HTMLTableCellElement | null;

            const block = new ButtonBlock();
            block.setOptions({
                editorState: EditorState.createWithContent(ContentState.createFromText(paragraphElement?.innerText.trim() ?? '')),
                url: element?.dataset.url ?? (isAnchor(paragraphElement) ? paragraphElement.href : null),
                alignment: element?.align as Alignment | null ?? 'center',
                color: rgbToHex(
                    paragraphElement?.style.color,
                    'rgb(255, 255, 255)'
                ),
                containerBackgroundColor: rgbToHex(
                    element?.style.backgroundColor,
                    'rgba(255, 255, 255, 0)'
                ),
                backgroundColor: rgbToHex(
                    paragraphElement?.style.backgroundColor,
                    'rgb(255, 255, 255)'
                ),
                borderStyle: (containerElement?.style.borderStyle as any) ?? 'solid',
                borderWidth: parseInt(containerElement?.style.borderWidth ?? '0'),
                borderCorners: {
                    topLeft: parseInt(containerElement?.style.borderTopLeftRadius ?? '4'),
                    topRight: parseInt(containerElement?.style.borderTopRightRadius ?? '4'),
                    bottomLeft: parseInt(containerElement?.style.borderBottomLeftRadius ?? '4'),
                    bottomRight: parseInt(containerElement?.style.borderBottomRightRadius ?? '4')
                },
                borderColor: rgbToHex(
                    containerElement?.style.borderColor,
                    'rgb(255, 255, 255)'
                ),
                padding: {
                    top: parseInt(paragraphElement?.style.paddingTop ?? '10'),
                    bottom: parseInt(paragraphElement?.style.paddingBottom ?? '10'),
                    left: parseInt(paragraphElement?.style.paddingLeft ?? '20'),
                    right: parseInt(paragraphElement?.style.paddingRight ?? '20')
                }
            });
            return block;
        }
    },
    image: {
        restore(options): ImageBlock {
            const element = document.createElement('table');
            element.innerHTML = options.html;
            const container = element.querySelector('.image-block') as HTMLTableCellElement | null;
            const image = element.querySelector('.image-block img') as HTMLImageElement | null;
            const block = new ImageBlock();

            block.setOptions({
                alignment: (container?.align as Alignment | null) ?? 'left',
                backgroundColor: rgbToHex(
                    container?.style.backgroundColor,
                    'rgb(100, 100, 100)'
                ),
                padding: {
                    top: parseInt(container?.style.paddingTop ?? '5'),
                    bottom: parseInt(container?.style.paddingBottom ?? '5'),
                    left: parseInt(container?.style.paddingLeft ?? '5'),
                    right: parseInt(container?.style.paddingRight ?? '5')
                },
                borderColor: rgbToHex(
                    image?.style.borderColor,
                    '#000'
                ),
                borderStyle: image?.style.borderStyle as MailTemplateVisualEditorBorderStyle,
                borderWidth: parseInt(image?.style.borderWidth ?? '0'),
                borderCorners: {
                    rounded: container?.classList.contains('rounded'),
                    topLeft: parseInt(image?.style.borderTopLeftRadius ?? '0'),
                    topRight: parseInt(image?.style.borderTopRightRadius ?? '0'),
                    bottomLeft: parseInt(image?.style.borderBottomLeftRadius ?? '0'),
                    bottomRight: parseInt(image?.style.borderBottomRightRadius ?? '0')
                },
                width: image?.width ?? 800,
                url: container?.dataset.url ?? image?.src ?? null,
                linkUrl: container?.dataset.linkUrl ?? null
            });
            return block;
        }
    },
    divider: {
        restore(options): DividerBlock {
            const template: HTMLTemplateElement | null = document.createElement('template');
            template.innerHTML = options.html.trim();
            const element = template.content.firstChild as HTMLTableCellElement | null;
            const container = element?.querySelector('.divider-block') as HTMLTableCellElement | null;
            const paragraphElement = element?.querySelector('p');

            const block = new DividerBlock();
            block.setOptions({
                color: rgbToHex(
                    paragraphElement?.style.borderTopColor,
                    'rgb(255, 255, 255)'
                ),
                backgroundColor: rgbToHex(
                    container?.style.backgroundColor,
                    'rgb(255, 255, 255)'
                ),
                style: (paragraphElement?.style.borderTopStyle as any) ?? 'solid',
                width: parseInt(paragraphElement?.style.width ?? '75'),
                thickness: parseInt(paragraphElement?.style.borderTopWidth ?? '1'),
                padding: {
                    top: parseInt(container?.style.paddingTop ?? '10'),
                    bottom: parseInt(container?.style.paddingBottom ?? '10'),
                    left: parseInt(container?.style.paddingLeft ?? '20'),
                    right: parseInt(container?.style.paddingRight ?? '20')
                }
            });
            return block;
        }
    },
    columns: {
        restore(options): ColumnsBlock {
            const container = document.createElement('div');
            container.innerHTML = options.html;
            const element = container.firstChild as HTMLDivElement | null;
            const columns: ColumnOptions[] = Array.from(element?.querySelectorAll('.columns-block-column') ?? []).map((node) => {
                const element = node as HTMLElement;
                let ratio = 1 / 2;
                element.classList.forEach((className) => {
                    if (className.includes('mj-column-per-')) {
                        ratio = parseFloat(className.replace('mj-column-per-', '').replace('-', '.')) / 100;
                    }
                });
                const styleElement = element.querySelector(
                    ':first-child > tbody > tr > td'
                ) as HTMLTableCellElement | null;
                const result = /([0-9]+)px ([a-z]+) (.+)$/.exec(
                    styleElement?.style.border ?? ''
                );
                const defaultOptions = createColumn();
                const backgroundImage = element.style.backgroundImage.replace('url("', '').replace('")', '');
                return {
                    ...defaultOptions,
                    ratio,
                    backgroundColor: rgbToHex(
                        styleElement?.style.backgroundColor,
                        defaultOptions.backgroundColor
                    ),
                    backgroundImage: backgroundImage.length > 0 ? backgroundImage : null,
                    borderWidth: result ?
                        parseInt(result[1] ?? defaultOptions.borderWidth.toString()) :
                        defaultOptions.borderWidth,
                    borderStyle: result ?
                        (result[2] as MailTemplateVisualEditorBorderStyle | undefined) ?? defaultOptions.borderStyle :
                        defaultOptions.borderStyle,
                    borderColor: result ?
                        rgbToHex(
                            result[3],
                            defaultOptions.borderColor
                        ) :
                        defaultOptions.borderColor,
                    borderCorners: {
                        topLeft: parseInt(styleElement?.style.borderTopLeftRadius ?? defaultOptions.borderCorners.topLeft.toString()),
                        topRight: parseInt(styleElement?.style.borderTopRightRadius ?? defaultOptions.borderCorners.topRight.toString()),
                        bottomLeft: parseInt(styleElement?.style.borderBottomLeftRadius ?? defaultOptions.borderCorners.bottomLeft.toString()),
                        bottomRight: parseInt(styleElement?.style.borderBottomRightRadius ?? defaultOptions.borderCorners.bottomRight.toString())
                    },
                    padding: {
                        top: parseInt(styleElement?.style.paddingTop ?? defaultOptions.padding.top.toString()),
                        bottom: parseInt(styleElement?.style.paddingBottom ?? defaultOptions.padding.bottom.toString()),
                        left: parseInt(styleElement?.style.paddingLeft ?? defaultOptions.padding.left.toString()),
                        right: parseInt(styleElement?.style.paddingRight ?? defaultOptions.padding.right.toString())
                    },
                    blocks: htmlWalker(
                        element.innerHTML,
                        (node) => {
                            const element = node as HTMLElement;
                            const blockRestorer = element.dataset.blockType ?
                                versions_0_0_0[element.dataset.blockType] :
                                null;
                            return blockRestorer?.restore({
                                version: options.version,
                                html: element.outerHTML,
                                quotationCode: options.quotationCode
                            });
                        },
                        new MailTemplateColumnsBlockHtmlWalkingStrategy()
                    ).filter((item) => item) as Block<any>[]
                };
            });
            const block = new ColumnsBlock();
            const styleElement = (element?.querySelector('div.columns-block-root') as HTMLDivElement | null ?? element);
            const verticalAlign = (
            element?.querySelector('.columns-block-column') as HTMLDivElement | null
        )?.style.verticalAlign as VerticalAlignment | null;
            block.setOptions({
                backgroundColor: rgbToHex(
                    styleElement?.style.backgroundColor,
                    'rgb(255, 255, 255)'
                ),
                backgroundImage: styleElement?.style.backgroundImage.replace('url("', '').replace('")', '') ?? null,
                columns,
                verticalAlignment: verticalAlign ?? 'top',
                disableResponsiveness: Boolean(element?.querySelector('.disable-responsiveness'))
            });
            return block;
        }
    },
    social: {
        restore(options): SocialBlock {
            let element = document.createElement('div');
            element.innerHTML = options.html;
            element = element.querySelector('.social-block') as HTMLDivElement;
            const container = element.querySelector('.social-block-container') as HTMLDivElement | null;
            const block = new SocialBlock();
            const icons: IconData[] = Array.from(container?.querySelectorAll('a[data-name]') ?? []).map((item) => {
                const element = item as HTMLAnchorElement;
                return {
                    name: element.dataset.name ?? '',
                    url: element.dataset.url ?? '',
                    logoUrl: element.dataset.iconUrl ?? '',
                    labelKey: element.dataset.labelKey ?? '',
                    activated: true
                };
            });
            block.setOptions({
                backgroundColor: rgbToHex(
                    container?.style.backgroundColor,
                    'rgb(100, 100, 100)'
                ),
                padding: {
                    top: parseInt(container?.style.paddingTop ?? '5'),
                    bottom: parseInt(container?.style.paddingBottom ?? '5'),
                    left: parseInt(container?.style.paddingLeft ?? '5'),
                    right: parseInt(container?.style.paddingRight ?? '5')
                },
                borderRadius: parseInt(container?.dataset.borderRadius ?? '0'),
                iconAlignment: (container?.style.textAlign as Alignment) ?? 'center',
                iconColor: container?.dataset.iconColor ?? '#fff',
                iconSize: parseInt(container?.dataset.iconSize ?? '26'),
                icons: unionBy(icons, defaultIcons.map((item) => ({ ...item, activated: false })), 'name')
            });
            return block;
        }
    },
    spacer: {
        restore(options): SpacerBlock {
            const template: HTMLTemplateElement | null = document.createElement('template');
            template.innerHTML = options.html.trim();
            const element = template.content.firstChild as HTMLTableCellElement | null;
            const container = element?.classList.contains('spacer-block') ?
                element :
            element?.querySelector('.spacer-block') as HTMLTableCellElement | null;
            const spaceElement = container?.querySelector('div');

            const block = new SpacerBlock();
            block.setOptions({
                backgroundColor: rgbToHex(
                    container?.style.backgroundColor,
                    'rgb(255, 255, 255)'
                ),
                height: parseInt(spaceElement?.style.height ?? '20')
            });
            return block;
        }
    }
} as {
    [type: string]: BlockRestorer
};
