// Dependencies
import React from 'react';
// Types
import { Picture } from '../objects/picture';

type Tab = 'uploader' | 'picker';

export type PictureContextValue = {
    onChangePictures?: (pictures: Picture[]) => void,
    singleFile?: boolean,
    disabled?: boolean,
    tabs?: Tab[],
}

const initialValue: PictureContextValue = {
    singleFile: false,
    tabs: ['picker', 'uploader']
};

export const PictureContext = React.createContext(initialValue);