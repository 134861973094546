import React from "react";
import { EditorState } from "draft-js";
import { convertFromHTML } from 'draft-convert';
import decorator from "./decorator";

const convertToRGB = (hex: string) => {
    const r = "0x" + hex[1] + hex[2];
    const g = "0x" + hex[3] + hex[4];
    const b = "0x" + hex[5] + hex[6];
    return `rgb(${+r}, ${+g}, ${+b})`;
};

export function restoreRichEditorStateFromHtml(
    styles: {[index: string]: React.CSSProperties},
    html: string
): EditorState {
    const contentState = convertFromHTML({
        htmlToStyle: (nodeName, node, currentStyle) => {
            if (nodeName === 'span' && !!node.style.fontSize) {
                return currentStyle.add(`FONTSIZE_${parseInt(node.style.fontSize)}`);
            }

            if (nodeName === 'span' && !!node.style.color) {
                let color = '';
                for (const [key, value] of Object.entries(styles)) {
                    if (key.includes('COLOR_') && convertToRGB(value.color) === node.style.color) {
                        color = key;
                    }
                }
                if (color !== '') {
                    return currentStyle.add(color);
                } 
                return currentStyle;
            } 
            return currentStyle;
        },
        //htmlToStyle: (nodeName, node, currentStyle) => {
        //console.log('nodeName : ', nodeName);
        //console.log('node : ', node);
        //console.log('currentStyle : ', currentStyle);
        //// if (nodeName === 'span' && !!node.style.color) {
        ////     for (const [key, value] of Object.entries(styleMap)) {
        ////         console.log('key : ', key);
        ////         console.log('value : ', value);
        ////         if (key.includes('COLOR_') && value === node.style.color){
        ////             return currentStyle.add(key);
        ////         }
        ////     }
        //// }
        //},
        htmlToEntity: (nodeName, node, createEntity) => {
            if (nodeName === 'a') {
                const entityConfig = {};
                entityConfig.link = node.href ? node.getAttribute('href') || node.href : node.href;
                const doc = new DOMParser().parseFromString(node.textContent, "text/html");
                //return doc.documentElement.textContent;
                //console.log('node.innerHTML : ', node.innerHTML);
                //console.log('node.textContent : ', doc.documentElement.textContent);
                entityConfig.text = doc.documentElement.textContent;
                node.textContent = doc.documentElement.textContent;
                return createEntity(
                    'LINK',
                    'MUTABLE',
                    entityConfig
                );
            }
            if (nodeName === 'img') {
                const entityConfig = {};
                entityConfig.src = node.getAttribute ? node.getAttribute('src') || node.src : node.src;
                entityConfig.alt = node.alt;
                entityConfig.height = node.style.height;
                entityConfig.width = node.style.width;

                return createEntity(
                    'IMAGE',
                    'MUTABLE',
                    entityConfig
                );
            }
        },
        htmlToBlock: (nodeName, node) => {
            if (nodeName === 'p' && !!node.style.textAlign) {
                return {
                    type: `align-${node.style.textAlign}`,
                    data: {}
                };
            }
            if (nodeName === 'blockquote') {
                return {
                    type: 'blockquote',
                    data: {}
                };
            }
        }
    })(html);
    return EditorState.createWithContent(contentState, decorator);
}
